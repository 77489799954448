import * as fromNotifications from '../actions/notifications.actions';

export interface NotificationsState {
    initialized: boolean;
}

export const initialState: NotificationsState = {
    initialized: false
};

export function reducer(
    state = initialState,
    action: fromNotifications.ActionsUnion
): NotificationsState {
    switch (action.type) {
        case fromNotifications.ActionTypes.NOTIFICATIONS_NOT_SUPPORTED:
        case fromNotifications.ActionTypes.INITIALIZED_PUSH_NOTIFICATIONS:
            return {
                ...state,
                initialized: true
            };

        case fromNotifications.ActionTypes.RESET_PUSH_NOTIFICATIONS_STATE:
            return {...initialState};

        default: {
            return state;
        }
    }
}

export const notificationsInitializedState = (state: NotificationsState) => state.initialized;
