// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-wrapper {
  margin: 15px 0;
}

.header-note {
  margin-top: 10px;
  font-size: 12px;
}
.header-note ul {
  padding-left: 20px;
}
.header-note li {
  padding: 2.5px 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNjaGVkdWxlLWJyZWFrLXZpZXcuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxjQUFBO0FBQ0Y7O0FBRUE7RUFDRSxnQkFBQTtFQUNBLGVBQUE7QUFDRjtBQUNFO0VBQ0Usa0JBQUE7QUFDSjtBQUVFO0VBQ0UsZ0JBQUE7QUFBSiIsImZpbGUiOiJzY2hlZHVsZS1icmVhay12aWV3LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmlucHV0LXdyYXBwZXIge1xuICBtYXJnaW46IDE1cHggMDtcbn1cblxuLmhlYWRlci1ub3RlIHtcbiAgbWFyZ2luLXRvcDogMTBweDtcbiAgZm9udC1zaXplOiAxMnB4O1xuXG4gIHVsIHtcbiAgICBwYWRkaW5nLWxlZnQ6IDIwcHg7XG4gIH1cblxuICBsaSB7XG4gICAgcGFkZGluZzogMi41cHggMDtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/schedule-break-view/schedule-break-view.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;AACA,4mBAA4mB","sourcesContent":[".input-wrapper {\n  margin: 15px 0;\n}\n\n.header-note {\n  margin-top: 10px;\n  font-size: 12px;\n\n  ul {\n    padding-left: 20px;\n  }\n\n  li {\n    padding: 2.5px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
