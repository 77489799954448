import * as fromWashReservation from '../actions/reservation.actions';
import {ImageUploadState, ImageUploadTask} from '../actions/reservation.actions';

export interface WashRequestsState {
    loading: boolean;
    loadingStateChange: boolean;
    reservationStatusLoading: boolean;
    loadingReservationDetails: boolean;
    imagesPendingUploading?: {[key: string]: ImageUploadTask};
}

export const initialState: WashRequestsState = {
    loading: true,
    loadingStateChange: false,
    reservationStatusLoading: false,
    loadingReservationDetails: false
};

export function reducer(
    state = initialState,
    action: fromWashReservation.ActionsUnion
): WashRequestsState {
    switch (action.type) {

        case fromWashReservation.ActionTypes.UPLOAD_IMAGES:
            return {
                ...state,
                loadingStateChange: true
            };

        case fromWashReservation.ActionTypes.ENQUEUE_IMAGE_UPLOAD_TASK:
            return {
                ...state,
                imagesPendingUploading: {
                    ...state.imagesPendingUploading,
                    [action.task.id]: action.task
                }
            };

        case fromWashReservation.ActionTypes.IMAGE_UPLOAD_TASK_STATE_CHANGE:
            const task = state.imagesPendingUploading[action.id];
            if (task) {
                const newTask = {
                    ...task,
                    state: action.state,
                    errorMessage: action.message
                } as ImageUploadTask;

                if (newTask.state === ImageUploadState.COMPLETED) {
                    const tasks = Object.values(state.imagesPendingUploading ?? {})
                        .filter(t => t.id !== action.id)
                        .reduce((p, c) => ({...p, [c.id]: c}), {} as {[key: string]: ImageUploadTask});
                    return {
                        ...state,
                        imagesPendingUploading: tasks
                    }
                }

                return {
                    ...state,
                    imagesPendingUploading: {
                        ...state.imagesPendingUploading,
                        [action.id]: newTask
                    }
                }
            }

            return state;

        default: {
            return state;
        }
    }
}

export const loadingState = (state: WashRequestsState) => state.loading;
export const reservationStatusLoadingState = (state: WashRequestsState) => state.reservationStatusLoading;
export const loadingReservationDetailsState = (state: WashRequestsState) => state.loadingReservationDetails;
export const selectImagesPendingUploading = (state: WashRequestsState) => state.imagesPendingUploading;
