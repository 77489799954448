// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gb-page-title {
  font-size: 28px;
}

.buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.buttons ion-button {
  margin-top: 10px;
  flex-grow: 1;
  width: 50%;
}
.buttons .coming-soon {
  font-size: 11px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImF2YWlsYWJpbGl0eS1icmVhay1kZXRhaWxzLXBhZ2UuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFBO0FBQ0Y7O0FBRUE7RUFDRSxnQkFBQTtFQUNBLGFBQUE7RUFDQSw4QkFBQTtBQUNGO0FBQ0U7RUFDRSxnQkFBQTtFQUNBLFlBQUE7RUFDQSxVQUFBO0FBQ0o7QUFFRTtFQUNFLGVBQUE7QUFBSiIsImZpbGUiOiJhdmFpbGFiaWxpdHktYnJlYWstZGV0YWlscy1wYWdlLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmdiLXBhZ2UtdGl0bGUge1xuICBmb250LXNpemU6IDI4cHg7XG59XG5cbi5idXR0b25zIHtcbiAgbWFyZ2luLXRvcDogMjBweDtcbiAgZGlzcGxheTogZmxleDtcbiAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xuXG4gIGlvbi1idXR0b24ge1xuICAgIG1hcmdpbi10b3A6IDEwcHg7XG4gICAgZmxleC1ncm93OiAxO1xuICAgIHdpZHRoOiA1MCU7XG4gIH1cblxuICAuY29taW5nLXNvb24ge1xuICAgIGZvbnQtc2l6ZTogMTFweDtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/availability-break-details/availability-break-details-page.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,8BAAA;AACF;AACE;EACE,gBAAA;EACA,YAAA;EACA,UAAA;AACJ;AAEE;EACE,eAAA;AAAJ;AACA,gyBAAgyB","sourcesContent":[".gb-page-title {\n  font-size: 28px;\n}\n\n.buttons {\n  margin-top: 20px;\n  display: flex;\n  justify-content: space-between;\n\n  ion-button {\n    margin-top: 10px;\n    flex-grow: 1;\n    width: 50%;\n  }\n\n  .coming-soon {\n    font-size: 11px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
