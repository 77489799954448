import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ImageUploadState, ImageUploadTask} from '../actions/reservation.actions';

@Component({
    selector: 'app-image-slider',
    templateUrl: './image-slider.component.html',
    styleUrls: ['./image-slider.component.scss']
})
export class ImageSliderComponent {
    @Input() images: Array<ImageUploadTask> = [];
    @Input() showRemoveImageButton = true;

    @Output() imageRemoved = new EventEmitter<string>();

    public IMAGE_UPLOAD_STATE = ImageUploadState;

    constructor() { }

    removeImage(image: string) {
        this.imageRemoved.emit(image);
    }
}
