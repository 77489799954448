import {ToastController} from '@ionic/angular';
import {FirebaseCrashlytics} from '@capacitor-firebase/crashlytics';

export function showToastMessage(toastController: ToastController, message: string,
                                 color: 'success' | 'danger' | 'warning',
                                 position: 'top' | 'bottom' = 'bottom',
                                 duration: number = 5000) {
    return toastController.create({
        duration,
        message: message,
        color,
        position,
        buttons: [
            {
                text: 'Close',
                role: 'cancel'
            }
        ]
    }).then(toast => toast.present());
}

export function showErrorMessage(toastController: ToastController,
                                 message: string | any,
                                 position: 'top' | 'bottom' = 'bottom') {
    if (!message) {
        return Promise.resolve();
    }
    // 'auth/provider-already-linked'
    console.log('received error message: ' + message?.toString());
    let strippedMessage: string;
    if (typeof message === 'object') {
        if (message.message) {
            strippedMessage = message.message;
        } else {
            strippedMessage = 'Internal error. Please try again';
        }
    } else {
        if (message.startsWith('Error: ')) {
            const splitMessage = message.split('Error: ');
            strippedMessage = splitMessage.slice(1).join();
        } else {
            strippedMessage = message;
        }
    }

    // This removes the suffix error messages from firebase.
    // Example: (auth/wrong-password).
    strippedMessage = strippedMessage?.replace(/\([a-zA-Z0-9-]+\/[a-zA-Z0-9-]+\)[.]*/g, "");

    strippedMessage = strippedMessage?.replace("Firebase: ", "");

    return showToastMessage(toastController, strippedMessage, 'danger', position, 5000);
}

export function showWarningMessage(toastController: ToastController, message: string,
                                   position: 'top' | 'bottom' = 'bottom',
                                   duration: number = 5000) {
    console.warn(message);
    return showToastMessage(toastController, message, 'warning', position, duration);
}

export function showSuccessMessage(toastController: ToastController, message: string,
                                   position: 'top' | 'bottom' = 'bottom',
                                   duration: number = 5000) {
    console.log(message);
    return showToastMessage(toastController, message, 'success', position, duration)
}

export function recordException(message: string) {
    FirebaseCrashlytics.recordException({message}).catch(e => {
        console.error('Error recording exception', e);
    });
}

export function toTitleCase(str: string): string {
    return str
        .toLowerCase()
        .split(' ')
        .map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
}

export function isDefined(val: any): boolean {
    return val !== undefined && val !== null;
}

export async function imagePathToFile(imagePath: string): Promise<File> {
    const response = await fetch(imagePath);
    const blob = await response.blob();

    const format = 'png';
    const fileName = `image.${format}`;

    return new File([blob], fileName, {
        type: `image/${format}`
    });
}
