import {Action, props, union} from '@ngrx/store';
import {createGbAction} from '@gobubbleapp/common-ui';
import {User} from '../gql/generated/graphql-schema';

export enum ActionTypes {
    LINK_PHONE_NUMBER = '[Auth] Link phone number',
    LINK_PHONE_NUMBER_SUCCESS = '[Auth] Link phone number success',
    CONFIRM_PHONE_NUMBER = '[Auth] Confirm phone number',
    CONFIRM_PHONE_NUMBER_SUCCESS = '[Auth] Confirm phone number success',
    USER_AUTH_STATE_CHANGED = '[Auth] User auth state changed',
    USER_LOGOUT = '[Auth] User logout',
    AUTHENTICATED = '[Auth] Authenticated',
    SIGN_IN_EMAIL_PASSWORD = '[Auth] Sign in with email and password',
    SIGN_UP_WITH_EMAIL_PASSWORD = 'user/sign_up',
    SIGN_IN_EMAIL_PASSWORD_SUCCESS = '[Auth] Sign in with email and password success',
    SIGN_IN_EMAIL_PASSWORD_FAILURE = '[Auth] Sign in with email and password failure',
    USER_LOG_OUT_SUCCESS = 'user/logout/success',
    USER_LOG_OUT_FAILURE = 'user/logout/failure',
    IS_EXPERT_RESULT = '[User] Is expert',
    GET_STATIC_MAP_URL = '[User] Get static map url',
    GET_STATIC_MAP_URL_SUCCESS = '[User] Get static map url success',
    GET_STATIC_MAP_URL_FAILURE = '[User] Get static map url failure',
}

export class LinkPhoneNumber implements Action {
    readonly type = ActionTypes.LINK_PHONE_NUMBER;

    constructor(public phoneNumber: string) {}
}

export class LinkPhoneNumberSuccess implements Action {
    readonly type = ActionTypes.LINK_PHONE_NUMBER_SUCCESS;
}

export class ConfirmPhoneNumber implements Action {
    readonly type = ActionTypes.CONFIRM_PHONE_NUMBER;

    constructor(public code: string) {}
}

export class ConfirmPhoneNumberSuccess implements Action {
    readonly type = ActionTypes.CONFIRM_PHONE_NUMBER_SUCCESS;
}

export class UserAuthStateChanged implements Action {
    readonly type = ActionTypes.USER_AUTH_STATE_CHANGED;

    constructor(public user?: User) {}
}

export class UserLogout implements Action {
    readonly type = ActionTypes.USER_LOGOUT;
}

export class Authenticated implements Action {
    readonly type = ActionTypes.AUTHENTICATED;
}

export class SignInWithEmailAndPassword implements Action {
    readonly type = ActionTypes.SIGN_IN_EMAIL_PASSWORD;

    constructor(public email: string, public password: string) {}
}

export class SignInWithEmailAndPasswordSuccess implements Action {
    readonly type = ActionTypes.SIGN_IN_EMAIL_PASSWORD_SUCCESS;
}

export class SignInWithEmailAndPasswordFailure implements Action {
    readonly type = ActionTypes.SIGN_IN_EMAIL_PASSWORD_FAILURE;
}

export class UserLogOutSuccess implements Action {
    readonly type = ActionTypes.USER_LOG_OUT_SUCCESS;
}

export class UserLogOutFailure implements Action {
    readonly type = ActionTypes.USER_LOG_OUT_FAILURE;
}

export class IsExpertResult implements Action {
    readonly type = ActionTypes.IS_EXPERT_RESULT;

    constructor(public isExpert: boolean) {
    }
}

export class GetStaticMapUrl implements Action {
    readonly type = ActionTypes.GET_STATIC_MAP_URL;

    constructor(public uid: string, public locationId: string) {
    }
}

export class GetStaticMapUrlSuccess implements Action {
    constructor(public locationId: string, public url: string) {
    }

    readonly type = ActionTypes.GET_STATIC_MAP_URL_SUCCESS;
}

export class GetStaticMapUrlFailure implements Action {
    readonly type = ActionTypes.GET_STATIC_MAP_URL_FAILURE;
}

export const RequestUserLocation = createGbAction(
    '/user/get_location',
    props<{correlationId: string}>()
);

export const UpdateUserLocation = createGbAction(
    '/user/update_location',
    props<{correlationId: string, latitude: number, longitude: number}>()
);

export const SignUpWithEmailPassword = createGbAction(
    '/user/sign_up',
    props<{correlationId: string, email: string, password: string, full_name: string}>()
);

const newActions = union({UpdateUserLocation, SignUpWithEmailPassword})

export type ActionsUnion =
    LinkPhoneNumber |
    LinkPhoneNumberSuccess |
    ConfirmPhoneNumber |
    ConfirmPhoneNumberSuccess |
    UserAuthStateChanged |
    Authenticated |
    UserLogout | UserLogOutSuccess | UserLogOutFailure |
    SignInWithEmailAndPassword |
    SignInWithEmailAndPasswordSuccess |
    SignInWithEmailAndPasswordFailure |
    IsExpertResult |
    GetStaticMapUrl | GetStaticMapUrlSuccess | GetStaticMapUrlFailure |
    typeof newActions
    ;
