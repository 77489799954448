import * as Types from '../generated/graphql-schema';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProviderBasicSettingsFragment = { __typename?: 'Provider', id: string, public_profile?: { __typename?: 'ProviderPublicProfile', display_name?: string | null, logo_image_url?: string | null, show_display_name_by_logo?: boolean | null, url_slug?: string | null, locale?: { __typename?: 'ProviderLocale', locale?: string | null, currency?: string | null } | null } | null, policies?: { __typename?: 'ProviderPolicies', payment_charge_policy?: { __typename?: 'PaymentChargePolicy', accepted_payment_methods?: Array<Types.PaymentMethodType> | null } | null } | null, business_settings?: { __typename?: 'ProviderBusinessSettings', services_category?: Types.ProviderServiceCategory | null } | null };

export type ProviderPaymentAccountFragment = { __typename?: 'ProviderPaymentAccount', id: string, charges_enabled?: boolean | null, payouts_enabled?: boolean | null, details_submitted?: boolean | null, requirements?: { __typename?: 'ProviderPaymentAccountRequirements', currently_due?: Array<string> | null, past_due?: Array<string> | null, eventually_due?: Array<string> | null } | null };

export type ProviderPricingFragment = { __typename?: 'ProviderPricing', monthly_fee?: number | null, transaction_fee_pricing?: { __typename?: 'ProviderTransactionFeePricing', transaction_fee_base?: number | null, transaction_fee_percent?: number | null, new_customer_lead_fee_percent?: number | null } | null };

export type ProviderPublicProfileSettingsFragment = { __typename?: 'Provider', id: string, public_profile?: { __typename?: 'ProviderPublicProfile', banner_image_url?: string | null, display_bio?: string | null, display_name?: string | null, logo_image_url?: string | null, show_display_name_by_logo?: boolean | null, url_slug?: string | null, locale?: { __typename?: 'ProviderLocale', locale?: string | null, currency?: string | null } | null } | null, business_settings?: { __typename?: 'ProviderBusinessSettings', country?: string | null, services_category?: Types.ProviderServiceCategory | null } | null, policies?: { __typename?: 'ProviderPolicies', payment_charge_policy?: { __typename?: 'PaymentChargePolicy', accepted_payment_methods?: Array<Types.PaymentMethodType> | null } | null } | null };

export type ProviderPublicProfileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProviderPublicProfileQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, provider?: { __typename?: 'Provider', id: string, public_profile?: { __typename?: 'ProviderPublicProfile', banner_image_url?: string | null, display_bio?: string | null, display_name?: string | null, logo_image_url?: string | null, show_display_name_by_logo?: boolean | null, url_slug?: string | null, locale?: { __typename?: 'ProviderLocale', locale?: string | null, currency?: string | null } | null } | null, business_settings?: { __typename?: 'ProviderBusinessSettings', country?: string | null, services_category?: Types.ProviderServiceCategory | null } | null, policies?: { __typename?: 'ProviderPolicies', payment_charge_policy?: { __typename?: 'PaymentChargePolicy', accepted_payment_methods?: Array<Types.PaymentMethodType> | null } | null } | null } | null } | null };

export type ProviderSavePublicProfileMutationVariables = Types.Exact<{
  publicProfile: Types.ProviderPublicProfileInput;
  businessSettings: Types.ProviderBusinessSettingsInput;
}>;


export type ProviderSavePublicProfileMutation = { __typename?: 'Mutation', providerSave?: { __typename?: 'Provider', id: string, public_profile?: { __typename?: 'ProviderPublicProfile', banner_image_url?: string | null, display_bio?: string | null, display_name?: string | null, logo_image_url?: string | null, show_display_name_by_logo?: boolean | null, url_slug?: string | null, locale?: { __typename?: 'ProviderLocale', locale?: string | null, currency?: string | null } | null } | null, business_settings?: { __typename?: 'ProviderBusinessSettings', country?: string | null, services_category?: Types.ProviderServiceCategory | null } | null, policies?: { __typename?: 'ProviderPolicies', payment_charge_policy?: { __typename?: 'PaymentChargePolicy', accepted_payment_methods?: Array<Types.PaymentMethodType> | null } | null } | null } | null };

export type ProviderPoliciesFragment = { __typename?: 'Provider', id: string, policies?: { __typename?: 'ProviderPolicies', payment_charge_policy?: { __typename?: 'PaymentChargePolicy', accepted_payment_methods?: Array<Types.PaymentMethodType> | null, upfront_charge_amount?: number | null, upfront_charge_percent?: number | null, remaining_amount_when_status?: Types.ReservationStatus | null } | null } | null, stripe_info?: { __typename?: 'ProviderStripeInfo', account?: { __typename?: 'ProviderPaymentAccount', id: string, charges_enabled?: boolean | null, payouts_enabled?: boolean | null, details_submitted?: boolean | null, requirements?: { __typename?: 'ProviderPaymentAccountRequirements', currently_due?: Array<string> | null, past_due?: Array<string> | null, eventually_due?: Array<string> | null } | null } | null } | null, pricing?: { __typename?: 'ProviderPricing', monthly_fee?: number | null, transaction_fee_pricing?: { __typename?: 'ProviderTransactionFeePricing', transaction_fee_base?: number | null, transaction_fee_percent?: number | null, new_customer_lead_fee_percent?: number | null } | null } | null };

export type ProviderPoliciesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProviderPoliciesQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, provider?: { __typename?: 'Provider', id: string, policies?: { __typename?: 'ProviderPolicies', payment_charge_policy?: { __typename?: 'PaymentChargePolicy', accepted_payment_methods?: Array<Types.PaymentMethodType> | null, upfront_charge_amount?: number | null, upfront_charge_percent?: number | null, remaining_amount_when_status?: Types.ReservationStatus | null } | null } | null, stripe_info?: { __typename?: 'ProviderStripeInfo', account?: { __typename?: 'ProviderPaymentAccount', id: string, charges_enabled?: boolean | null, payouts_enabled?: boolean | null, details_submitted?: boolean | null, requirements?: { __typename?: 'ProviderPaymentAccountRequirements', currently_due?: Array<string> | null, past_due?: Array<string> | null, eventually_due?: Array<string> | null } | null } | null } | null, pricing?: { __typename?: 'ProviderPricing', monthly_fee?: number | null, transaction_fee_pricing?: { __typename?: 'ProviderTransactionFeePricing', transaction_fee_base?: number | null, transaction_fee_percent?: number | null, new_customer_lead_fee_percent?: number | null } | null } | null } | null } | null };

export type ProviderSavePoliciesMutationVariables = Types.Exact<{
  input: Types.ProviderPoliciesInput;
}>;


export type ProviderSavePoliciesMutation = { __typename?: 'Mutation', providerSave?: { __typename?: 'Provider', id: string, policies?: { __typename?: 'ProviderPolicies', payment_charge_policy?: { __typename?: 'PaymentChargePolicy', accepted_payment_methods?: Array<Types.PaymentMethodType> | null, upfront_charge_amount?: number | null, upfront_charge_percent?: number | null, remaining_amount_when_status?: Types.ReservationStatus | null } | null } | null, stripe_info?: { __typename?: 'ProviderStripeInfo', account?: { __typename?: 'ProviderPaymentAccount', id: string, charges_enabled?: boolean | null, payouts_enabled?: boolean | null, details_submitted?: boolean | null, requirements?: { __typename?: 'ProviderPaymentAccountRequirements', currently_due?: Array<string> | null, past_due?: Array<string> | null, eventually_due?: Array<string> | null } | null } | null } | null, pricing?: { __typename?: 'ProviderPricing', monthly_fee?: number | null, transaction_fee_pricing?: { __typename?: 'ProviderTransactionFeePricing', transaction_fee_base?: number | null, transaction_fee_percent?: number | null, new_customer_lead_fee_percent?: number | null } | null } | null } | null };

export type ProviderGetAccountSessionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProviderGetAccountSessionQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, provider?: { __typename?: 'Provider', id: string, stripe_info?: { __typename?: 'ProviderStripeInfo', account_session?: { __typename?: 'ProviderAccountSession', client_secret?: string | null } | null } | null } | null } | null };

export const ProviderBasicSettingsFragmentDoc = gql`
    fragment ProviderBasicSettings on Provider {
  id
  public_profile {
    display_name
    logo_image_url
    show_display_name_by_logo
    locale {
      locale
      currency
    }
    url_slug
  }
  policies {
    payment_charge_policy {
      accepted_payment_methods
    }
  }
  business_settings {
    services_category
  }
}
    `;
export const ProviderPublicProfileSettingsFragmentDoc = gql`
    fragment ProviderPublicProfileSettings on Provider {
  ...ProviderBasicSettings
  public_profile {
    banner_image_url
    display_bio
  }
  business_settings {
    country
  }
}
    ${ProviderBasicSettingsFragmentDoc}`;
export const ProviderPaymentAccountFragmentDoc = gql`
    fragment ProviderPaymentAccount on ProviderPaymentAccount {
  id
  charges_enabled
  payouts_enabled
  details_submitted
  requirements {
    currently_due
    past_due
    eventually_due
  }
}
    `;
export const ProviderPricingFragmentDoc = gql`
    fragment ProviderPricing on ProviderPricing {
  monthly_fee
  transaction_fee_pricing {
    transaction_fee_base
    transaction_fee_percent
    new_customer_lead_fee_percent
  }
}
    `;
export const ProviderPoliciesFragmentDoc = gql`
    fragment ProviderPolicies on Provider {
  id
  policies {
    payment_charge_policy {
      accepted_payment_methods
      upfront_charge_amount
      upfront_charge_percent
      remaining_amount_when_status
    }
  }
  stripe_info {
    account {
      ...ProviderPaymentAccount
    }
  }
  pricing {
    ...ProviderPricing
  }
}
    ${ProviderPaymentAccountFragmentDoc}
${ProviderPricingFragmentDoc}`;
export const ProviderPublicProfileDocument = gql`
    query ProviderPublicProfile {
  me {
    id
    provider {
      ...ProviderPublicProfileSettings
    }
  }
}
    ${ProviderPublicProfileSettingsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProviderPublicProfileGQL extends Apollo.Query<ProviderPublicProfileQuery, ProviderPublicProfileQueryVariables> {
    document = ProviderPublicProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProviderSavePublicProfileDocument = gql`
    mutation ProviderSavePublicProfile($publicProfile: ProviderPublicProfileInput!, $businessSettings: ProviderBusinessSettingsInput!) {
  providerSave(
    input: {public_profile: $publicProfile, business_settings: $businessSettings}
  ) {
    ...ProviderPublicProfileSettings
  }
}
    ${ProviderPublicProfileSettingsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProviderSavePublicProfileGQL extends Apollo.Mutation<ProviderSavePublicProfileMutation, ProviderSavePublicProfileMutationVariables> {
    document = ProviderSavePublicProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProviderPoliciesDocument = gql`
    query ProviderPolicies {
  me {
    id
    provider {
      ...ProviderPolicies
    }
  }
}
    ${ProviderPoliciesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProviderPoliciesGQL extends Apollo.Query<ProviderPoliciesQuery, ProviderPoliciesQueryVariables> {
    document = ProviderPoliciesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProviderSavePoliciesDocument = gql`
    mutation ProviderSavePolicies($input: ProviderPoliciesInput!) {
  providerSave(input: {policies: $input}) {
    ...ProviderPolicies
  }
}
    ${ProviderPoliciesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProviderSavePoliciesGQL extends Apollo.Mutation<ProviderSavePoliciesMutation, ProviderSavePoliciesMutationVariables> {
    document = ProviderSavePoliciesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProviderGetAccountSessionDocument = gql`
    query ProviderGetAccountSession {
  me {
    id
    provider {
      id
      stripe_info {
        account_session {
          client_secret
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProviderGetAccountSessionGQL extends Apollo.Query<ProviderGetAccountSessionQuery, ProviderGetAccountSessionQueryVariables> {
    document = ProviderGetAccountSessionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }