import {Component, Input, OnInit} from '@angular/core';
import {ReservationService} from '../../services/reservation.service';
import {ReservationGetServiceDetailsQuery} from '../../gql/reservations/single-reservation.graphql-gen';
import {EMPTY, Observable} from 'rxjs';
import {ApolloQueryResult} from '@apollo/client/core';
import {Store} from '@ngrx/store';
import {AppError, CloseModal} from '@gobubbleapp/common-ui';

@Component({
    selector: 'app-reservation-provider-services',
    templateUrl: './reservation-provider-services.component.html',
    styleUrls: ['./reservation-provider-services.component.scss']
})
export class ReservationProviderServicesComponent implements OnInit {

    @Input() reservationId: string;
    @Input() itemId: string;
    @Input() showCloseButton: boolean;

    public query$: Observable<ApolloQueryResult<ReservationGetServiceDetailsQuery>> = EMPTY;

    constructor(
        private reservationService: ReservationService,
        private store$: Store
    ) {
    }

    ngOnInit() {
        if (this.reservationId && this.itemId) {
            this.query$ = this.reservationService.getReservationItemServices(`${this.reservationId}`,
                `${this.itemId}`);
        } else {
            this.store$.dispatch(AppError({error: "Please select an item"}));
            this.closeModal();
            return
        }
    }

    closeModal() {
        this.store$.dispatch(CloseModal({}));
    }

}
