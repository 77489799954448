// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .no-expandable-accordion .ion-accordion-toggle-icon {
  display: none;
}

.service-line-item {
  padding: var(--half-ion-padding) 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJlc2VydmF0aW9uLXByb3ZpZGVyLXNlcnZpY2VzLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtBQUNGOztBQUVBO0VBQ0Usa0NBQUE7QUFDRiIsImZpbGUiOiJyZXNlcnZhdGlvbi1wcm92aWRlci1zZXJ2aWNlcy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IDo6bmctZGVlcCAubm8tZXhwYW5kYWJsZS1hY2NvcmRpb24gLmlvbi1hY2NvcmRpb24tdG9nZ2xlLWljb24ge1xuICBkaXNwbGF5OiBub25lO1xufVxuXG4uc2VydmljZS1saW5lLWl0ZW0ge1xuICBwYWRkaW5nOiB2YXIoLS1oYWxmLWlvbi1wYWRkaW5nKSAwO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/provider-services/reservation-provider-services/reservation-provider-services.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,kCAAA;AACF;AACA,oiBAAoiB","sourcesContent":[":host ::ng-deep .no-expandable-accordion .ion-accordion-toggle-icon {\n  display: none;\n}\n\n.service-line-item {\n  padding: var(--half-ion-padding) 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
