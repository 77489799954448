import * as Types from '../generated/graphql-schema';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProUserProfileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProUserProfileQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, display_name?: string | null, email?: string | null, phone_number?: string | null } | null };

export type UserRequestPhoneNumberUpdateMutationVariables = Types.Exact<{
  input: Types.UpdatePhoneNumberInput;
}>;


export type UserRequestPhoneNumberUpdateMutation = { __typename?: 'Mutation', userRequestPhoneNumberUpdate: { __typename?: 'PhoneNumberVerificationResponse', success?: boolean | null, message?: string | null, channel?: Types.VerificationChannel | null, status?: Types.VerificationStatus | null } };

export type UserVerifyPhoneNumberMutationVariables = Types.Exact<{
  input: Types.ConfirmPhoneNumberInput;
}>;


export type UserVerifyPhoneNumberMutation = { __typename?: 'Mutation', userConfirmPhoneNumberUpdate: { __typename?: 'MutationResponse', success?: boolean | null, message?: string | null } };

export type UserInitiateDeleteAccountMutationVariables = Types.Exact<{
  channel?: Types.InputMaybe<Types.VerificationChannel>;
}>;


export type UserInitiateDeleteAccountMutation = { __typename?: 'Mutation', userInitiateDeleteAccount: { __typename?: 'InitiateDeleteAccountResponse', channel?: Types.VerificationChannel | null, message: string } };

export type UserConfirmDeleteAccountMutationVariables = Types.Exact<{
  code?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UserConfirmDeleteAccountMutation = { __typename?: 'Mutation', userConfirmDeleteAccount: { __typename?: 'ConfirmDeleteAccountResponse', message?: string | null, confirmed: boolean } };

export type UserRegisterDeviceTokenMutationVariables = Types.Exact<{
  input: Types.UserDeviceTokenInput;
}>;


export type UserRegisterDeviceTokenMutation = { __typename?: 'Mutation', userRegisterDeviceToken: { __typename?: 'RegisterDeviceTokenResponse', success?: boolean | null, message?: string | null } };

export type UserDeleteDeviceTokenMutationVariables = Types.Exact<{
  deviceId: Types.Scalars['String'];
}>;


export type UserDeleteDeviceTokenMutation = { __typename?: 'Mutation', userDeleteDeviceToken: { __typename?: 'DeleteDeviceTokenResponse', success?: boolean | null, message?: string | null } };

export const ProUserProfileDocument = gql`
    query ProUserProfile {
  me {
    id
    display_name
    email
    phone_number
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProUserProfileGQL extends Apollo.Query<ProUserProfileQuery, ProUserProfileQueryVariables> {
    document = ProUserProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserRequestPhoneNumberUpdateDocument = gql`
    mutation UserRequestPhoneNumberUpdate($input: UpdatePhoneNumberInput!) {
  userRequestPhoneNumberUpdate(input: $input) {
    success
    message
    channel
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserRequestPhoneNumberUpdateGQL extends Apollo.Mutation<UserRequestPhoneNumberUpdateMutation, UserRequestPhoneNumberUpdateMutationVariables> {
    document = UserRequestPhoneNumberUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserVerifyPhoneNumberDocument = gql`
    mutation UserVerifyPhoneNumber($input: ConfirmPhoneNumberInput!) {
  userConfirmPhoneNumberUpdate(input: $input) {
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserVerifyPhoneNumberGQL extends Apollo.Mutation<UserVerifyPhoneNumberMutation, UserVerifyPhoneNumberMutationVariables> {
    document = UserVerifyPhoneNumberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserInitiateDeleteAccountDocument = gql`
    mutation UserInitiateDeleteAccount($channel: VerificationChannel) {
  userInitiateDeleteAccount(data: {channel: $channel}) {
    channel
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserInitiateDeleteAccountGQL extends Apollo.Mutation<UserInitiateDeleteAccountMutation, UserInitiateDeleteAccountMutationVariables> {
    document = UserInitiateDeleteAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserConfirmDeleteAccountDocument = gql`
    mutation UserConfirmDeleteAccount($code: String) {
  userConfirmDeleteAccount(data: {code: $code}) {
    message
    confirmed
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserConfirmDeleteAccountGQL extends Apollo.Mutation<UserConfirmDeleteAccountMutation, UserConfirmDeleteAccountMutationVariables> {
    document = UserConfirmDeleteAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserRegisterDeviceTokenDocument = gql`
    mutation UserRegisterDeviceToken($input: UserDeviceTokenInput!) {
  userRegisterDeviceToken(input: $input) {
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserRegisterDeviceTokenGQL extends Apollo.Mutation<UserRegisterDeviceTokenMutation, UserRegisterDeviceTokenMutationVariables> {
    document = UserRegisterDeviceTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserDeleteDeviceTokenDocument = gql`
    mutation UserDeleteDeviceToken($deviceId: String!) {
  userDeleteDeviceToken(deviceId: $deviceId) {
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserDeleteDeviceTokenGQL extends Apollo.Mutation<UserDeleteDeviceTokenMutation, UserDeleteDeviceTokenMutationVariables> {
    document = UserDeleteDeviceTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }