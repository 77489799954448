// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-title {
  font-size: 22px;
  font-family: "Patua One", serif;
  --color: var(--ion-color-nice-gray);
  color: var(--ion-color-nice-gray);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBhZ2UtdGl0bGUuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFBO0VBQ0EsK0JBQUE7RUFDQSxtQ0FBQTtFQUNBLGlDQUFBO0FBQ0YiLCJmaWxlIjoicGFnZS10aXRsZS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5wYWdlLXRpdGxlIHtcbiAgZm9udC1zaXplOiAyMnB4O1xuICBmb250LWZhbWlseTogJ1BhdHVhIE9uZScsIHNlcmlmO1xuICAtLWNvbG9yOiB2YXIoLS1pb24tY29sb3ItbmljZS1ncmF5KTtcbiAgY29sb3I6IHZhcigtLWlvbi1jb2xvci1uaWNlLWdyYXkpO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/page-title/page-title.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,+BAAA;EACA,mCAAA;EACA,iCAAA;AACF;AACA,wfAAwf","sourcesContent":[".page-title {\n  font-size: 22px;\n  font-family: 'Patua One', serif;\n  --color: var(--ion-color-nice-gray);\n  color: var(--ion-color-nice-gray);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
