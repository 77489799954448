// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons {
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  margin: var(--ion-margin) auto;
  gap: var(--half-ion-padding);
}
.buttons ion-button {
  flex-grow: 1;
}

.cancel-fee-options-group {
  display: flex;
  flex-direction: column;
  margin: var(--ion-padding) 0;
}
.cancel-fee-options-group .partial-fee-input {
  max-width: 100px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNhbmNlbC1jb25maXJtYXRpb24uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxhQUFBO0VBQ0EsOEJBQUE7RUFDQSxnQkFBQTtFQUNBLDhCQUFBO0VBQ0EsNEJBQUE7QUFDRjtBQUNFO0VBQ0UsWUFBQTtBQUNKOztBQUdBO0VBQ0UsYUFBQTtFQUNBLHNCQUFBO0VBQ0EsNEJBQUE7QUFBRjtBQUVFO0VBQ0UsZ0JBQUE7QUFBSiIsImZpbGUiOiJjYW5jZWwtY29uZmlybWF0aW9uLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmJ1dHRvbnMge1xuICBkaXNwbGF5OiBmbGV4O1xuICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG4gIG1heC13aWR0aDogMzUwcHg7XG4gIG1hcmdpbjogdmFyKC0taW9uLW1hcmdpbikgYXV0bztcbiAgZ2FwOiB2YXIoLS1oYWxmLWlvbi1wYWRkaW5nKTtcblxuICBpb24tYnV0dG9uIHtcbiAgICBmbGV4LWdyb3c6IDE7XG4gIH1cbn1cblxuLmNhbmNlbC1mZWUtb3B0aW9ucy1ncm91cCB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gIG1hcmdpbjogdmFyKC0taW9uLXBhZGRpbmcpIDA7XG5cbiAgLnBhcnRpYWwtZmVlLWlucHV0IHtcbiAgICBtYXgtd2lkdGg6IDEwMHB4O1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/reservation-details/cancel-confirmation/cancel-confirmation.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,8BAAA;EACA,4BAAA;AACF;AACE;EACE,YAAA;AACJ;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,4BAAA;AAAF;AAEE;EACE,gBAAA;AAAJ;AACA,46BAA46B","sourcesContent":[".buttons {\n  display: flex;\n  justify-content: space-between;\n  max-width: 350px;\n  margin: var(--ion-margin) auto;\n  gap: var(--half-ion-padding);\n\n  ion-button {\n    flex-grow: 1;\n  }\n}\n\n.cancel-fee-options-group {\n  display: flex;\n  flex-direction: column;\n  margin: var(--ion-padding) 0;\n\n  .partial-fee-input {\n    max-width: 100px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
