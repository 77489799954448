// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.duration-inputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.duration-inputs ion-item {
  flex: 1 1 160px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByb3ZpZGVyLXNlcnZpY2Utb3B0aW9uLWZvcm0uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxhQUFBO0VBQ0EsbUJBQUE7RUFDQSxlQUFBO0FBQ0Y7QUFDRTtFQUNFLGVBQUE7QUFDSiIsImZpbGUiOiJwcm92aWRlci1zZXJ2aWNlLW9wdGlvbi1mb3JtLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmR1cmF0aW9uLWlucHV0cyB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZGlyZWN0aW9uOiByb3c7XG4gIGZsZXgtd3JhcDogd3JhcDtcblxuICBpb24taXRlbSB7XG4gICAgZmxleDogMSAxIDE2MHB4O1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/provider-services/provider-service-option-form/provider-service-option-form.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AACF;AACE;EACE,eAAA;AACJ;AACA,ohBAAohB","sourcesContent":[".duration-inputs {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n\n  ion-item {\n    flex: 1 1 160px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
