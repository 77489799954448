import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private _storage: Storage | null = null;

    constructor(private storage: Storage) {
        this.storage.create().then(storage => this._storage = storage);
    }

    public set(key: string, value: any) {
        return this._storage?.set(key, value);
    }

    public get(key: string) {
        return this._storage?.get(key);
    }

    public forEach(iterator: (value: any, key: string, iterationNumber: Number) => any) {
        return this._storage?.forEach(iterator);
    }

    public remove(key: string) {
        return this._storage?.remove(key);
    }
}
