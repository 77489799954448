import * as Types from '../generated/graphql-schema';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProUserProfileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProUserProfileQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, display_name?: string | null, email?: string | null, phone_number?: string | null } | null };

export type UserRequestPhoneNumberUpdateMutationVariables = Types.Exact<{
  input: Types.UpdatePhoneNumberInput;
}>;


export type UserRequestPhoneNumberUpdateMutation = { __typename?: 'Mutation', userRequestPhoneNumberUpdate: { __typename?: 'PhoneNumberVerificationResponse', success?: boolean | null, message?: string | null, channel?: Types.VerificationChannel | null } };

export type UserVerifyPhoneNumberMutationVariables = Types.Exact<{
  input: Types.ConfirmPhoneNumberInput;
}>;


export type UserVerifyPhoneNumberMutation = { __typename?: 'Mutation', userConfirmPhoneNumberUpdate: { __typename?: 'MutationResponse', success?: boolean | null, message?: string | null } };

export const ProUserProfileDocument = gql`
    query ProUserProfile {
  me {
    id
    display_name
    email
    phone_number
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProUserProfileGQL extends Apollo.Query<ProUserProfileQuery, ProUserProfileQueryVariables> {
    document = ProUserProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserRequestPhoneNumberUpdateDocument = gql`
    mutation UserRequestPhoneNumberUpdate($input: UpdatePhoneNumberInput!) {
  userRequestPhoneNumberUpdate(input: $input) {
    success
    message
    channel
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserRequestPhoneNumberUpdateGQL extends Apollo.Mutation<UserRequestPhoneNumberUpdateMutation, UserRequestPhoneNumberUpdateMutationVariables> {
    document = UserRequestPhoneNumberUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserVerifyPhoneNumberDocument = gql`
    mutation UserVerifyPhoneNumber($input: ConfirmPhoneNumberInput!) {
  userConfirmPhoneNumberUpdate(input: $input) {
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserVerifyPhoneNumberGQL extends Apollo.Mutation<UserVerifyPhoneNumberMutation, UserVerifyPhoneNumberMutationVariables> {
    document = UserVerifyPhoneNumberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }