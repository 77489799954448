import {throwError} from 'rxjs';
import {getAuth} from '@angular/fire/auth';
import {environment} from '../../environments/environment';

export function handleErrorMessage(e: any) {
    console.error('Handling error message', e);

    if (typeof e === 'string') {
        return throwError(e);
    }

    if (typeof e === 'object') {
        if (e.code === 'unavailable') {
            console.log(e);
            return throwError('Unable to connect to the backend service. Check your internet connection');
        }

        if (e.error && typeof e.error === 'object' && e.error.message) {
            return throwError(e.error.message);
        }

        if (e.message) {
            return throwError(e.message);
        }
    }

    return throwError('Internal error. Please try again');
}

export function getTenantScopedAuth() {
    const auth = getAuth();
    auth.tenantId = environment.authTenantId;
    return auth;
}
