import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {Action, Store} from '@ngrx/store';

import * as userActions from '../actions/user.actions';
import {
    IsExpertResult,
    RequestUserLocation,
    SignUpWithEmailPassword,
    UpdateUserLocation, UserLogOutFailure,
    UserLogOutSuccess
} from '../actions/user.actions';
import {State} from '../reducers';
import {AppError, LogAnalyticEvent, NavigateToRoot, Noop, SuccessMessage} from '../actions/app.actions';
import {defaultIfEmpty, defer, EMPTY, forkJoin, Observable, of, throwError} from 'rxjs';
import {NavController} from '@ionic/angular';
import {ResetPushNotificationsState, SetupPushNotifications} from '../actions/notifications.actions';
import {ExpertsUiService} from '@gobubbleapp/experts-ui';
import {UserService} from '@gobubbleapp/users-ui';
import {CommunicationService} from '@gobubbleapp/communication-ui';
import {ReuploadPendingImageUploads} from '../actions/reservation.actions';
import {Geolocation, Position} from '@capacitor/geolocation';
import {nanoid} from 'nanoid';
import {AccountService} from '../services/account.service';
import {LOGIN_PATH} from '../constants/app-routes';
import {getTenantScopedAuth} from '../utils/FirebaseFunctionUtils';
import {createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile} from '@angular/fire/auth';
import {getGqlFirstErrorMessage} from "@gobubbleapp/common-ui";
import {recordException} from "../utils/app.utils";

function internalGetCurrentPosition(options: PositionOptions = {}): Promise<Position> {
    console.log('Getting user geolocation');
    return new Promise<Position>((resolve, reject) => {
        const id = Geolocation.watchPosition(options, (position, err) => {
            id.then(i => Geolocation.clearWatch({id: i}));
            if (err) {
                reject(err);
                return;
            }
            resolve(position);
        });
    });
}

@Injectable({
    providedIn: 'root'
})
export class UserEffects {
    constructor(
        private actions$: Actions,
        private store$: Store<State>,
        private userService: UserService,
        private navController: NavController,
        private expertsService: ExpertsUiService,
        private accountService: AccountService,
        private communicationService: CommunicationService
    ) {}

    // @Effect() linkPhoneNumber$: Observable<Action> = this.actions$.pipe(
    //     ofType<userActions.LinkPhoneNumber>(userActions.ActionTypes.LINK_PHONE_NUMBER),
    //     switchMap(action => {
    //         if (this.afAuth.auth.currentUser) {
    //             return this.userService.startPhoneNumberVerification(action.phoneNumber).pipe(
    //                 switchMap(result => {
    //                     if (this.userService.phoneNumberVerificationPending(result)) {
    //                         return this.ngZone.run(() => this.navController.navigateForward('/confirm-phone-number'))
    //                             .then(() => new Noop());
    //                     }
    //
    //                     if (this.userService.phoneNumberVerificationApproved(result)) {
    //                         return this.ngZone.run(() => this.navController.navigateRoot('/'))
    //                             .then(() => new Noop());
    //                     }
    //
    //                     if (this.userService.phoneNumberVerificationDenied(result)) {
    //                         return of(new AppError('Failed to verify phone number. Please try again'));
    //                     }
    //                 })
    //             );
    //         }
    //         return of(new AppError('Please sign in again'));
    //     }),
    //     catchError(e => of(new AppError(e))));
    //
    // @Effect() confirmPhoneNumber$: Observable<Action> = this.actions$.pipe(
    //     ofType<userActions.ConfirmPhoneNumber>(userActions.ActionTypes.CONFIRM_PHONE_NUMBER),
    //     withLatestFrom(this.store$),
    //     switchMap(([action, storeState]) => {
    //         if (storeState.user.phoneNumberPendingVerification) {
    //             return this.userService.verifyPhoneNumber(storeState.user.phoneNumberPendingVerification, String(action.code)).pipe(
    //                 map(result => {
    //                     if (this.userService.phoneNumberVerificationApproved(result)) {
    //                         return new ConfirmPhoneNumberSuccess();
    //                     }
    //
    //                     if (this.userService.phoneNumberVerificationPending(result) ||
    //                         this.userService.phoneNumberVerificationDenied(result)) {
    //                         return new AppError('Failed to verify phone number. Please try again');
    //                     }
    //                 }));
    //         } else {
    //             return of(new AppError('Error verifying phone number. Please try again.'));
    //         }
    //     }),
    //     catchError(e => of(new AppError(e))));

    confirmPhoneNumberSuccess = createEffect(() => this.actions$.pipe(
        ofType<userActions.ConfirmPhoneNumberSuccess>(userActions.ActionTypes.CONFIRM_PHONE_NUMBER_SUCCESS),
        switchMap(() => defer(() => this.navController.navigateRoot('/')).pipe(
            map(() => new SuccessMessage('Phone number verified')),
            catchError(e => of(new AppError(e)))
        ))
    ));

    userLogout$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<userActions.UserLogout>(userActions.ActionTypes.USER_LOGOUT),
        switchMap(() => forkJoin([
                this.accountService.deleteDeviceToken().pipe(
                    switchMap(result => {
                        if (result.loading) {
                            return EMPTY;
                        }

                        const error = getGqlFirstErrorMessage(result);
                        if (error) {
                            console.error('Error registering device token', error);
                            recordException(error);
                            return throwError(() => error);
                        }

                        if (!result?.data?.userDeleteDeviceToken?.success) {
                            const msg = `Error registering device token: `
                                + `${result?.data?.userDeleteDeviceToken?.message}`;
                            console.error(msg);
                            recordException(msg);
                            return throwError(() => msg);
                        }

                        console.log("Device token deleted");
                        return of(undefined);
                    })
                ),
                defer(() => this.accountService.logoutOfStripeInstances().then(() => {
                    console.log('Logged out of stripe instances');
                })),
                this.communicationService.removePushNotificationToken().pipe(
                    defaultIfEmpty(undefined)
                )
            ])
        ),
        switchMap(() => defer(() => getTenantScopedAuth().signOut())),
        mergeMap(() => [new UserLogOutSuccess(), new ResetPushNotificationsState()]),
        catchError(e => {
            console.error('Error deleting push notification', e);
            return of(new AppError('Error signing out'), new UserLogOutFailure());
        })
    ));

    userLoggedOut$ = createEffect(() => this.actions$.pipe(
        ofType<userActions.UserLogOutSuccess>(userActions.ActionTypes.USER_LOG_OUT_SUCCESS),
        map(() => NavigateToRoot({correlationId: nanoid(), path: `/${LOGIN_PATH}`})),
        catchError(e => {
            console.error('Error signing out', e);
            return of(new AppError('Error signing out'));
        })
    ));

    userAuthStateChanged$ = createEffect(() => this.actions$.pipe(
        ofType<userActions.UserAuthStateChanged>(userActions.ActionTypes.USER_AUTH_STATE_CHANGED),
        map(action => action.user ? new userActions.Authenticated() : new Noop()),
        catchError(e => of(new AppError(e)))
    ));

    signInEmailPassword$ = createEffect(() => this.actions$.pipe(
        ofType<userActions.SignInWithEmailAndPassword>(userActions.ActionTypes.SIGN_IN_EMAIL_PASSWORD),
        switchMap((action) => defer(() =>
            signInWithEmailAndPassword(getTenantScopedAuth(), action.email, action.password)).pipe(
            mergeMap(() => [
                new userActions.SignInWithEmailAndPasswordSuccess(),
                new LogAnalyticEvent('login', {method: 'email_password'}),
                NavigateToRoot({correlationId: nanoid(), path: '/'})
            ]),
            catchError(e => [new AppError(e), new userActions.SignInWithEmailAndPasswordFailure()])
        )),
    ));

    signUpEmailPassword$ = createEffect(() => this.actions$.pipe(
        ofType(SignUpWithEmailPassword),
        switchMap((action) => defer(() =>
            createUserWithEmailAndPassword(getTenantScopedAuth(), action.email, action.password)).pipe(
            switchMap(user => defer(() => updateProfile(user.user, {
                displayName: action.full_name
            }))),
            mergeMap(() => [
                new userActions.SignInWithEmailAndPasswordSuccess(),
                new LogAnalyticEvent('login', {method: 'email_password'}),
                NavigateToRoot({correlationId: nanoid(), path: '/'})
            ]),
            catchError(e => [new AppError(e), new userActions.SignInWithEmailAndPasswordFailure()])
        )),
    ));

    getIfUserIsExpert$ = createEffect(() => this.actions$.pipe(
        ofType<userActions.Authenticated>(userActions.ActionTypes.AUTHENTICATED),
        switchMap(() => this.expertsService.isExpert().pipe(
            mergeMap(isExpert => isExpert ? [
                new IsExpertResult(!!isExpert),
                new ReuploadPendingImageUploads(),
                new SetupPushNotifications()
            ] : [new IsExpertResult(!!isExpert)]),
            catchError(e => {
                console.error('Error getting if expert', e);
                return of(new IsExpertResult(false));
            })
        ))
    ));

    requestUserLocation$ = createEffect(() => this.actions$.pipe(
        ofType(RequestUserLocation),
        switchMap(action =>
            defer(() => internalGetCurrentPosition()).pipe(
                map(c => UpdateUserLocation({
                    correlationId: action.correlationId,
                    latitude: c.coords.latitude, longitude: c.coords.longitude
                })),
                catchError(e => {
                    console.error('error getting location', e);
                    return of(new Noop());
                })
            )
        )
    ));

    // @Effect() getStaticMapUrl$: Observable<Action> = this.actions$.pipe(
    //     ofType<userActions.GetStaticMapUrl>(userActions.ActionTypes.GET_STATIC_MAP_URL),
    //     withLatestFrom(this.store$),
    //     switchMap(([action, storeState]) => {
    //         if (!storeState.user.staticMapImageUrls[action.locationId]) {
    //             return this.userService.getLocationStaticMapUrl(action.uid, action.locationId).pipe(
    //                 map(url => new userActions.GetStaticMapUrlSuccess(action.locationId, url)),
    //                 catchError(e => {
    //                     console.error('Error getting location static map url', e);
    //                     return of(new userActions.GetStaticMapUrlFailure());
    //                 })
    //             );
    //
    //         } else {
    //             return of(new Noop());
    //         }
    //     })
    // );
}
