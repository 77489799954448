import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as appActions from '../actions/app.actions';
import {
    ContactSupport,
    NavigateBackward,
    NavigateTo,
    NavigateToRoot,
    Noop,
    OpenLinkInBrowser
} from '../actions/app.actions';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {recordException, showErrorMessage, showSuccessMessage} from '../utils/app.utils';
import {NavController, Platform, ToastController} from '@ionic/angular';
import {Action, Store} from '@ngrx/store';
import {State} from '../reducers';
import {Capacitor} from '@capacitor/core';
import {Observable, of} from 'rxjs';
import {Keyboard} from '@capacitor/keyboard';
import {FirebaseAnalytics} from '@capacitor-community/firebase-analytics';
import {AppError, CloseModal, GbModalController, showWarningMessage} from '@gobubbleapp/common-ui';
import {Browser} from '@capacitor/browser';
import {RemoteConfigsService} from '../services/remote-configs.service';
import {MessageLevel} from "../gql/generated/graphql-schema";

@Injectable()
export class AppEffects {

    public toastInstance: {[key: string]: HTMLIonToastElement | undefined};
    private isDesktop: boolean;
    private toastPosition: 'top' | 'bottom' = 'bottom';

    constructor(
        private actions$: Actions,
        private toastController: ToastController,
        private store$: Store<State>,
        private modalController: GbModalController,
        private navController: NavController,
        private platform: Platform,
        private remoteConfig: RemoteConfigsService,
    ) {
        this.isDesktop = this.platform.is('desktop');
        this.toastPosition = this.isDesktop ? 'top' : 'bottom';
    }

    error$ = createEffect(() => this.actions$.pipe(
        ofType<appActions.AppError>(appActions.ActionTypes.APP_ERROR),
        switchMap(action => showErrorMessage(this.toastController, action.error, this.toastPosition))
    ), {dispatch: false});

    successMessage$ = createEffect(() => this.actions$.pipe(
        ofType<appActions.SuccessMessage>(appActions.ActionTypes.SUCCESS_MESSAGE),
        map(action => {
            console.log(action.message);
            return showSuccessMessage(this.toastController, action.message, this.toastPosition, action.duration)
        })
    ), {dispatch: false});

    dismissToast$ = createEffect(() => this.actions$.pipe(
        ofType<appActions.DismissToastMessage>(appActions.ActionTypes.DISMISS_TOAST_MESSAGE),
        map(action => {
            if (this.toastInstance[action.id]) {
                this.toastInstance[action.id].dismiss().catch(e => console.error('Error dismissing toast', e));
            }
            this.toastInstance[action.id] = undefined;
        })
    ), {dispatch: false});

    // @Effect() openUrl$: Observable<Action> = this.actions$.pipe(
    //     ofType(appActions.ActionTypes.HYDRATED, appActions.ActionTypes.OPEN_URL),
    //     withLatestFrom(this.store$),
    //     map(([action, storeState]) => {
    //         return new Noop();
    //     }),
    //     catchError(e => of(new AppError(e)))
    // );

    closeModal$ = createEffect(() => this.actions$.pipe(
        ofType(appActions.ActionTypes.CLOSE_MODAL, CloseModal),
        map(action => {
            let keyboardHide = Promise.resolve();
            if (Capacitor.isPluginAvailable('Keyboard')) {
                keyboardHide = Keyboard.hide();
            }
            return keyboardHide.then(() => setTimeout(() => this.modalController.dismiss(action.data), 150));
        })
    ), {dispatch: false});

    recordException$ = createEffect(() => this.actions$.pipe(
        ofType<appActions.RecordException>(appActions.ActionTypes.RECORD_EXCEPTION),
        map(action => recordException(action.message))
    ), {dispatch: false});

    logAnalyticEvent$ = createEffect(() => this.actions$.pipe(
        ofType<appActions.LogAnalyticEvent>(appActions.ActionTypes.LOG_ANALYTIC_EVENT),
        map(action => FirebaseAnalytics.logEvent({name: action.eventName, params: action.eventParams})),
        catchError(e => {
            recordException('Error logging analytic event: error=' + e.toString());
            return of(new Noop());
        })
    ), {dispatch: false});

    navigateBack$ = createEffect(() => this.actions$.pipe(
        ofType<appActions.NavigateBack>(appActions.ActionTypes.NAVIGATE_BACK),
        map(() => this.navController.pop())
    ), {dispatch: false});

    contactSupport$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ContactSupport),
        withLatestFrom(this.store$),
        map(([action, storeState]) => {
            const subject = action.subject ?? '';
            const email = action.email ?? storeState.user?.user?.email ?? '';
            const description = action.description ?? '';
            return OpenLinkInBrowser({
                correlationId: action.correlationId,
                link: this.remoteConfig.helpCenterUrl +
                    '/hc/en-us/requests/new?' + encodeURI(`tf_subject=${subject}&tf_description=${description}&tf_anonymous_requester_email=${email}`)
            })
        })
    ))

    openLinkInBrowser$ = createEffect(() => this.actions$.pipe(
        ofType(OpenLinkInBrowser),
        map(action => Browser.open({url: action.link}))
    ), {dispatch: false})

    navigateToRoot$ = createEffect(() => this.actions$.pipe(
        ofType(NavigateToRoot),
        map(action => this.navController.navigateRoot(action.path))
    ), {dispatch: false});

    navigateBackward$ = createEffect(() => this.actions$.pipe(
        ofType(NavigateBackward),
        map(action => this.navController.navigateBack(action.path, action.options).catch(e => {
            console.error('Error navigating', e);
        }))
    ), {dispatch: false});

    navigateTo$ = createEffect(() => this.actions$.pipe(
        ofType(NavigateTo),
        map(action => this.navController.navigateForward(action.path, action.options).catch(e => {
            console.error('Error navigating', e);
        }))
    ), {dispatch: false});

    displayMessageBasedOnLevel$ = createEffect(() => this.actions$.pipe(
        ofType(appActions.DisplayMessageBasedOnLevel),
        map(action => {
            switch (action.message.level ?? MessageLevel.Info) {
                case MessageLevel.Info:
                    return showSuccessMessage(this.toastController, action.message.message, this.toastPosition);
                case MessageLevel.Warning:
                    return showWarningMessage(this.toastController, action.message.message, this.toastPosition);
                case MessageLevel.Error:
                    return showErrorMessage(this.toastController, action.message.message, this.toastPosition);
            }
        })
    ), {dispatch: false});
}
