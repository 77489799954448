import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-icon-selector',
    templateUrl: './icon-selector.component.html',
    styleUrls: ['./icon-selector.component.scss']
})
export class IconSelectorComponent implements OnInit {
    public icons$: Observable<string[]>;
    @Input() public iconsGroup: string;

    @Output() iconSelected = new EventEmitter<string>();

    constructor(private http: HttpClient) {}

    ngOnInit() {
        this.icons$ = this.http.get<Record<string, string[]>>('assets/provider-service-icons/icons.json')
            .pipe(map(iconMap => {
                if (!this.iconsGroup) {
                    return Object.values(iconMap).reduce((acc, val) => acc.concat(val), []);
                }

                if (iconMap[this.iconsGroup]) {
                    return iconMap[this.iconsGroup];
                }

                const snakeCase = this.iconsGroup.toLowerCase().replace("_", "-");
                if (iconMap[snakeCase]) {
                    return iconMap[snakeCase];
                }

                return [];
            }));
    }

    selectIcon(icon: string) {
        this.iconSelected.emit(icon);
    }
}
