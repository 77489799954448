import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Platform, ToastController} from '@ionic/angular';
import {Store} from '@ngrx/store';
import {State} from '../reducers';
import * as washRequestActions from '../actions/reservation.actions';
import {
    EnqueueImageUploadTask,
    ImageUploadState,
    ImageUploadTask,
    ImageUploadTaskStateChange,
    StartImageUploadTask
} from '../actions/reservation.actions';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {AppError, Noop, RecordException} from '../actions/app.actions';
import {defer, of} from 'rxjs';
import {ReservationService} from '../services/reservation.service';
import {AppLauncher} from '@capacitor/app-launcher';
import {StorageService} from '../services/storage.service';

@Injectable()
export class ReservationEffects {
    private static EXPERT_RESERVATION_IMAGES = 'expert_reservation_images';

    constructor(private actions$: Actions,
                private toastController: ToastController,
                private store$: Store<State>,
                private washRequestService: ReservationService,
                private storage: StorageService,
                private platform: Platform,
    ) {
    }

    startImageUploadTask$ = createEffect(() => this.actions$.pipe(
        ofType<washRequestActions.StartImageUploadTask>(washRequestActions.ActionTypes.START_IMAGE_UPLOAD_TASK),
        withLatestFrom(this.store$),
        switchMap(([action, state]) => {
            if (!state.user?.user?.uid) {
                return of(new AppError("You are not logged in"));
            }
            const pendingImages = Object.values(state.washRequests.imagesPendingUploading ?? {})
            if (pendingImages.length) {
                const task = pendingImages[0];
                return this.washRequestService.uploadImage(state.user?.user?.uid, task).pipe(
                    map(t => {
                        t.task.then(
                            () => {
                                this.store$.dispatch(new ImageUploadTaskStateChange(task.id, ImageUploadState.COMPLETED));
                                this.store$.dispatch(new StartImageUploadTask())
                            },
                            (e) => {
                                setTimeout(() => this.store$.dispatch(new StartImageUploadTask(action.retries + 1)),
                                    2 ** (Math.max(12, action.retries ?? 3)) * 1000)
                                this.store$.dispatch(new ImageUploadTaskStateChange(task.id, ImageUploadState.FAILED, 0, e.toString()));
                            }
                        );
                        return new ImageUploadTaskStateChange(task.id, ImageUploadState.STARTED);
                    }),
                    catchError(e => {
                        console.error("Error starting upload task", e);
                        return of(
                            new RecordException("Error starting image upload: e=" + e.toString()),
                            new AppError("Error uploading images. Please try again"),
                            new ImageUploadTaskStateChange(task.id, ImageUploadState.FAILED, 0, e.toString())
                        );
                    })
                );
            }
            return of(new Noop());
        })
    ));

    navigateToLocation$ = createEffect(() => this.actions$.pipe(
        ofType<washRequestActions.NavigateToLocation>(washRequestActions.ActionTypes.NAVIGATE_TO_LOCATION),
        switchMap(action => {
            let url = 'https://www.google.com/maps/place/' + `${action.latitude},${action.longitude}`;
            if (this.platform.is('ios')) {
                url = 'maps://?daddr=' + action.latitude + ',' + action.longitude;
            }
            return defer(() => AppLauncher.canOpenUrl({ url })).pipe(
                switchMap((canOpen) => {
                    if (canOpen) {
                        return defer(() => AppLauncher.openUrl({url})).pipe(
                            map(() => new Noop())
                        )
                    } else {
                        return of(
                            new RecordException("Error opening maps url: error=App launcher cannot open"),
                            new AppError('Error opening maps')
                        );
                    }
                })
            )
        }),
        catchError(e => {
            return of(
                new RecordException('Exception navigating to location: '
                    + `location=${location.toString()}, message=${e}`),
                new AppError(e)
            );
        })
    ));

    private static getReservationImageStorageKey(id: string) {
        return `${ReservationEffects.EXPERT_RESERVATION_IMAGES}_${id}`;
    }

    private static isReservationImageStorageKey(id: string) {
        return id.startsWith(ReservationEffects.EXPERT_RESERVATION_IMAGES);
    }

    reUploadPendingReservations$ = createEffect(() => this.actions$.pipe(
        ofType<washRequestActions.ReuploadPendingImageUploads>(washRequestActions.ActionTypes.REUPLOAD_PENDING_IMAGE_UPLOADS),
        switchMap(() => {
            return defer(() => this.storage.forEach((value, key) => {
                const task = JSON.parse(value) as ImageUploadTask;
                if (ReservationEffects.isReservationImageStorageKey(key)) {
                    this.store$.dispatch(new EnqueueImageUploadTask(task));
                }
            })).pipe(
                map(() => new StartImageUploadTask()),
                catchError(e => {
                    console.error("Error start image upload tasks", e);
                    return of(new RecordException("Error starting image upload tasks: error=" + e.toString()));
                })
            )
        })
    ))

    enqueueImageUploadTask$ = createEffect(() => this.actions$.pipe(
        ofType<washRequestActions.EnqueueImageUploadTask>(washRequestActions.ActionTypes.ENQUEUE_IMAGE_UPLOAD_TASK),
        switchMap(action => {
            return defer(() => this.storage.set(ReservationEffects.getReservationImageStorageKey(action.task.id),
                JSON.stringify(action.task))).pipe(
                map(() => new Noop()),
                catchError(e => {
                    return of(new RecordException("Error storing expert reservation image locally: error=" + e.toString()));
                })
            )
        })
    ));

    imageUploadTaskStateChanged$ = createEffect(() => this.actions$.pipe(
        ofType<washRequestActions.ImageUploadTaskStateChange>(washRequestActions.ActionTypes.IMAGE_UPLOAD_TASK_STATE_CHANGE),
        switchMap((action) => {
            switch (action.state) {
                case ImageUploadState.COMPLETED:
                    return defer(() => this.storage.remove(ReservationEffects.getReservationImageStorageKey(action.id))).pipe(
                        map(() => new Noop()),
                        catchError(e => {
                            return of(new RecordException("Error removing expert reservation image locally: error=" + e.toString()));
                        })
                    )
                case ImageUploadState.FAILED:
                    return of(
                        new RecordException("Error uploading image. Will retry: e" + action.message ?? ''),
                        new AppError("Error uploading image. Will retry")
                    );
                default:
                    return of(new Noop());
            }
        })
    ));
}
