import {Component, Input, OnInit} from '@angular/core';
import {ImageUploadTask} from '../actions/reservation.actions';

@Component({
    selector: 'app-task-status',
    templateUrl: './task-status.component.html',
    styleUrls: ['./task-status.component.scss']
})
export class TaskStatusComponent {

    @Input() tasks: ImageUploadTask[];

    constructor() { }

}
