// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.current-etc-description {
  font-size: 14px;
}

.added-mins {
  font-size: 100px;
}

.reason-input {
  font-size: 14px;
}

.reason-input-container {
  border: 1px solid var(--ion-border-color);
  padding: 16px;
  border-radius: 20px;
  margin-top: 10px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVwZGF0ZS1jb21wbGV0aW9uLXRpbWUuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFBO0FBQ0Y7O0FBRUE7RUFDRSxnQkFBQTtBQUNGOztBQUVBO0VBQ0UsZUFBQTtBQUNGOztBQUVBO0VBQ0UseUNBQUE7RUFDQSxhQUFBO0VBQ0EsbUJBQUE7RUFDQSxnQkFBQTtBQUNGIiwiZmlsZSI6InVwZGF0ZS1jb21wbGV0aW9uLXRpbWUuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY3VycmVudC1ldGMtZGVzY3JpcHRpb24ge1xuICBmb250LXNpemU6IDE0cHg7XG59XG5cbi5hZGRlZC1taW5zIHtcbiAgZm9udC1zaXplOiAxMDBweDtcbn1cblxuLnJlYXNvbi1pbnB1dCB7XG4gIGZvbnQtc2l6ZTogMTRweDtcbn1cblxuLnJlYXNvbi1pbnB1dC1jb250YWluZXIge1xuICBib3JkZXI6IDFweCBzb2xpZCB2YXIoLS1pb24tYm9yZGVyLWNvbG9yKTtcbiAgcGFkZGluZzogMTZweDtcbiAgYm9yZGVyLXJhZGl1czogMjBweDtcbiAgbWFyZ2luLXRvcDogMTBweDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/update-completion-time/update-completion-time.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,yCAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AACF;AACA,oxBAAoxB","sourcesContent":[".current-etc-description {\n  font-size: 14px;\n}\n\n.added-mins {\n  font-size: 100px;\n}\n\n.reason-input {\n  font-size: 14px;\n}\n\n.reason-input-container {\n  border: 1px solid var(--ion-border-color);\n  padding: 16px;\n  border-radius: 20px;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
