// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-header-note {
  padding: 0 20px;
}

.item-icon {
  max-width: 35px;
  max-height: 35px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByb3ZpZGVyLXNlcnZpY2UtaXRlbXMtbGlzdC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNFLGVBQUE7QUFBRjs7QUFHQTtFQUNFLGVBQUE7RUFDQSxnQkFBQTtBQUFGIiwiZmlsZSI6InByb3ZpZGVyLXNlcnZpY2UtaXRlbXMtbGlzdC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIlxuLmxpc3QtaGVhZGVyLW5vdGUge1xuICBwYWRkaW5nOiAwIDIwcHg7ICAgLy8gbWF0Y2hpbmcgdGhlIGlvbi1oZWFkZXItdGl0bGVcbn1cblxuLml0ZW0taWNvbiB7XG4gIG1heC13aWR0aDogMzVweDtcbiAgbWF4LWhlaWdodDogMzVweDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/provider-services/provider-service-items-list/provider-service-items-list.component.scss"],"names":[],"mappings":"AACA;EACE,eAAA;AAAF;;AAGA;EACE,eAAA;EACA,gBAAA;AAAF;AACA,whBAAwhB","sourcesContent":["\n.list-header-note {\n  padding: 0 20px;   // matching the ion-header-title\n}\n\n.item-icon {\n  max-width: 35px;\n  max-height: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
