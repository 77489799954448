import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Reservation} from '../../gql/generated/graphql-schema';
import * as moment from 'moment-timezone';
import {ScheduleService} from '../../services/schedule.service';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {CalendarEvent, CalendarEventTimesChangedEvent} from 'angular-calendar';
import {delay, map, takeWhile} from 'rxjs/operators';
import {State} from '../../reducers';
import {Store} from '@ngrx/store';
import {AppError, CloseModal, getGqlFirstErrorMessage, handleConflictErrors} from '@gobubbleapp/common-ui';
import {AlertController, IonContent} from '@ionic/angular';
import {ReservationService} from '../../services/reservation.service';
import {getEpochSec, MILITARY_TIME_FORMAT, momentTimezoneFromDate, momentTimezoneFromTimeSlot} from '../../utils/DateTimeUtils';

@Component({
    selector: 'app-reschedule-reservation',
    templateUrl: './reschedule-reservation.component.html',
    styleUrls: ['./reschedule-reservation.component.scss'],
})
export class RescheduleReservationComponent implements OnInit, OnDestroy {

    @ViewChild(IonContent, { read: IonContent }) content: IonContent;

    @Input() public reservation: Reservation
    public viewDate: Date;

    public calendarEvents$: Observable<CalendarEvent<unknown>[]>;
    public reschedulingReservationEvent: CalendarEvent<unknown>;
    public refreshCalendar = new ReplaySubject<unknown>();
    public loading$: Observable<boolean>;
    public loadingSave: boolean;

    private scheduleErrorSubscription: Subscription;
    displayedStartTime: string;
    displayedEndTime: string;

    constructor(
        private scheduleService: ScheduleService,
        private reservationService: ReservationService,
        private store$: Store<State>,
        private alertController: AlertController
    ) { }

    ngOnInit(): void {
        if (this.reservation) {
            this.viewDate = moment(this.reservation.time_slot.start_time * 1000).toDate();
            this.setDisplayedTimeFieldToReservation();
        }

        const schedule = this.scheduleService.getSchedule().valueChanges;

        this.scheduleErrorSubscription = schedule.subscribe(s => {
            if (s.errors?.length && !s?.data?.schedule?.length) {
                this.store$.dispatch(AppError({error: s?.errors}));
            }
        });

        this.loading$ = schedule.pipe(map(s => s.loading));
        this.calendarEvents$ = schedule.pipe(
            map(schedule => schedule?.data?.schedule
                ?.filter(ts => {
                    if (ts.start_time) {
                        return moment(ts.start_time * 1000).date() === this.viewDate?.getDate();
                    }
                    return false;
                })
                ?.map(timeSlot => {
                    const reservation = 'reservation' in timeSlot ? timeSlot.reservation : undefined;
                    const breakSlot = 'break' in timeSlot ? timeSlot.break : undefined;
                    const primary = reservation?.id === this.reservation?.id ? 'rgba(var(--ion-color-primary-rgb), 0.6)' : 'rgba(var(--ion-color-medium-rgb), 0.6)';
                    const secondary = primary;
                    const itemsLength = reservation?.items?.length ?? 0;
                    const reservationTitle = `<b>${itemsLength} ${itemsLength > 1 ? 'Vehicles' : 'Vehicle'}</b><br>`
                        + `${reservation?.location?.address ?? ''}`;
                    const isDraggable = reservation?.id === this?.reservation?.id;
                    const calendarEvent = {
                        start: new Date(timeSlot.start_time * 1000),
                        end: new Date(timeSlot.end_time * 1000),
                        title: reservation ? reservationTitle : (breakSlot?.name ?? 'Break'),
                        color: {primary, secondary},
                        meta: timeSlot,
                        draggable: isDraggable,
                        cssClass: isDraggable ? 'draggable-event' : ''
                    } as CalendarEvent
                    if (isDraggable) {
                        this.reschedulingReservationEvent = calendarEvent;
                    }
                    return calendarEvent;
                })
            )
        );

        this.calendarEvents$.pipe(
            takeWhile(d => !d?.length, true),
            delay(500)      // wait until events are loaded
        ).subscribe(response => {
            if (this.reservation?.id && response?.length) {
                this.scrollToDragabbleEvent();
            }
        })
    }

    setDisplayedTimeFieldToReservation() {
        const {startTime, endTime} = momentTimezoneFromTimeSlot(this.reservation.time_slot);
        this.displayedStartTime = startTime.format(MILITARY_TIME_FORMAT);
        this.displayedEndTime = endTime.format(MILITARY_TIME_FORMAT);
    }

    ngOnDestroy() {
        this.scheduleErrorSubscription?.unsubscribe();
    }

    closeModal() {
        this.store$.dispatch(CloseModal({}));
    }

    async eventTimesChanged({event, newStart, newEnd}: CalendarEventTimesChangedEvent) {
        event.start = newStart;
        event.end = newEnd;
        this.refreshCalendar.next(undefined);
        setTimeout(() => this.scrollToDragabbleEvent(), 300);

        this.displayedStartTime = momentTimezoneFromDate(newStart, this.reservation.time_slot.time_zone_id).format(MILITARY_TIME_FORMAT);
        this.displayedEndTime = momentTimezoneFromDate(newEnd, this.reservation.time_slot.time_zone_id).format(MILITARY_TIME_FORMAT);
    }

    async scrollToDragabbleEvent() {
        const targetElement = document.getElementsByClassName('draggable-event')?.[0];
        if (targetElement) {
            // adding a 100 to the offset to center the events
            this.content.scrollToPoint(undefined, (targetElement as any)?.offsetTop - 100, 700);
        }
    }

    changeViewDate(operator: 'subtract' | 'add', unit: 'day', amount: number) {
        if (!this.reschedulingReservationEvent) {
            return;
        }

        switch (operator) {
            case 'add':
                this.reschedulingReservationEvent.start = moment(this.reschedulingReservationEvent.start).add(amount, unit).toDate();
                this.reschedulingReservationEvent.end = moment(this.reschedulingReservationEvent.end).add(amount, unit).toDate();
                break;

            case 'subtract':
                this.reschedulingReservationEvent.start = moment(this.reschedulingReservationEvent.start).subtract(amount, unit).toDate();
                this.reschedulingReservationEvent.end = moment(this.reschedulingReservationEvent.end).subtract(amount, unit).toDate();
                break;
        }
        setTimeout(() => this.scrollToDragabbleEvent(), 300);
    }

    saveNewTime(force = false) {
        if (!this.reservation) {
            this.store$.dispatch(AppError({error: "Reservation is not defined to reschedule"}));
            return;
        }

        if (!this.reschedulingReservationEvent?.start) {
            this.store$.dispatch(AppError({error: "New reservation is not defined to reschedule"}));
            return;
        }

        this.reservationService.reschedule(this.reservation.id, {
            new_arrival_time: getEpochSec(this.reschedulingReservationEvent?.start),
            force
        }).subscribe(async mutationResponse => {

            this.loadingSave = !!mutationResponse?.loading;

            if (this.loadingSave) {
                return;
            }

            const message = getGqlFirstErrorMessage(mutationResponse);
            if (message) {
                const hasConflict = await handleConflictErrors({
                    alertController: this.alertController,
                    mutationResponse,
                    handlerOnConfirm: () => this.saveNewTime(true),
                    handleNotFound: true
                });

                if (hasConflict) {
                    return;
                }

                this.store$.dispatch(AppError({error: message}));
                return;
            }

            this.closeModal();
        })
    }

    updateNowDateFromStr(value: string | string[]) {
        this.viewDate = new Date(Array.isArray(value) ? value?.[0] : value);

        for (const date of [this.reschedulingReservationEvent.start, this.reschedulingReservationEvent.end]) {
            date.setFullYear(this.viewDate.getFullYear(), this.viewDate.getMonth(), this.viewDate.getDate());
        }

        setTimeout(() => this.scrollToDragabbleEvent(), 300);
    }

    onReservationWindowFieldUpdate() {
        const [startTimeHours, startTimeMin] = this.displayedStartTime.split(":");
        const [endTimeHours, endTimeMin] = this.displayedEndTime.split(":");

        if (!startTimeHours || !startTimeMin || !endTimeHours || !endTimeMin) {
            return;
        }

        if (parseInt(`${startTimeHours}${startTimeMin}`) > parseInt(`${endTimeHours}${endTimeMin}`)) {
            // If the start time is after the end time, revert to the original.
            this.setDisplayedTimeFieldToReservation();
            this.refreshCalendar.next(undefined);
            return;
        }

        this.reschedulingReservationEvent.start.setHours(parseInt(startTimeHours), parseInt(startTimeMin));
        this.reschedulingReservationEvent.end.setHours(parseInt(endTimeHours), parseInt(endTimeMin));

        this.refreshCalendar.next(undefined);
    }
}
