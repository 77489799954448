import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ProviderServiceDetailedInfoFragment} from '../../gql/reservations/provider-services.graphql-gen';
import {ProviderServiceDisplayPropertiesUpdateInput} from '../../gql/generated/graphql-schema';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import {ProviderServicesService} from '../../services/provider-services.service';
import {AppError, CloseModal, getGqlFirstErrorMessage} from '@gobubbleapp/common-ui';

@Component({
    selector: 'app-provider-service-option-config-form',
    templateUrl: './provider-service-option-config-form.component.html',
    styleUrls: ['./provider-service-option-config-form.component.scss']
})
export class ProviderServiceOptionConfigFormComponent implements OnInit {
    @Input() existingItem?: ProviderServiceDetailedInfoFragment;

    loading: boolean;
    form: UntypedFormGroup;

    constructor(
        private store: Store,
        private formBuilder: UntypedFormBuilder,
        private providerServicesService: ProviderServicesService,
        private changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            option_selection_required: [this.existingItem?.displayProperties?.option_selection_required],
            prompt: [this.existingItem?.displayProperties?.prompt],
            allow_none_option: [this.existingItem?.displayProperties?.allow_none_option]
        });
    }

    closeModal() {
        this.store.dispatch(CloseModal({}));
    }

    onSubmit() {

        if (!this.form.valid) {
            this.store.dispatch(AppError({error: 'Please fill out all required fields'}));
            return;
        }

        if (!this.existingItem?.id) {
            this.store.dispatch(AppError({error: 'No service ID found'}));
            return;
        }

        const displayPropertiesUpdateInput: ProviderServiceDisplayPropertiesUpdateInput = {
            option_selection_required: Boolean(this.form.value.option_selection_required),
            prompt: this.form.value.prompt,
            allow_none_option: Boolean(this.form.value.allow_none_option),
        }

        this.providerServicesService.updateService({
            id: this.existingItem.id,
            displayProperties: displayPropertiesUpdateInput
        }).subscribe(result => {
            this.loading = result.loading;
            if (result.loading) {
                return;
            }

            const error = getGqlFirstErrorMessage(result);
            if (error) {
                this.store.dispatch(AppError({error}));
                // otherwise loading indicator does not update
                this.changeDetectorRef.detectChanges();
                return;
            }

            this.closeModal();
        });
    }
}
