import * as Types from '../generated/graphql-schema';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProviderAvailabilityLocationFragment = { __typename?: 'ProviderLocation', address?: string | null, name?: string | null, radius_meters?: number | null, latitude?: number | null, longitude?: number | null };

export type ProviderAvailabilitySeriesDetailsFragment = { __typename?: 'ProviderAvailabilitySeries', id: string, frequency?: Types.AvailabilityFrequency | null, recurring_days?: Array<number> | null, location?: { __typename?: 'ProviderLocation', address?: string | null, name?: string | null, radius_meters?: number | null, latitude?: number | null, longitude?: number | null } | null, reservation_window?: { __typename?: 'MilitaryTimeSlot', start_time_military?: string | null, end_time_military?: string | null, time_zone_id?: string | null } | null };

export type ProviderAvailabilityDetailsFragment = { __typename?: 'ProviderAvailability', id: string, location?: { __typename?: 'ProviderLocation', address?: string | null, name?: string | null, radius_meters?: number | null, latitude?: number | null, longitude?: number | null } | null, reservation_window?: { __typename?: 'TimeSlot', start_time?: number | null, end_time?: number | null } | null, time_slots?: Array<{ __typename?: 'BreakTimeSlot', id?: string | null, start_time?: number | null, end_time?: number | null, break?: { __typename?: 'ProviderBreak', id?: string | null, name?: string | null } | null } | { __typename?: 'ReservationTimeSlot', id?: string | null, start_time?: number | null, end_time?: number | null } | { __typename?: 'TimeSlot', id?: string | null, start_time?: number | null, end_time?: number | null }> | null, series?: { __typename?: 'ProviderAvailabilitySeries', id: string, frequency?: Types.AvailabilityFrequency | null, recurring_days?: Array<number> | null, location?: { __typename?: 'ProviderLocation', address?: string | null, name?: string | null, radius_meters?: number | null, latitude?: number | null, longitude?: number | null } | null, reservation_window?: { __typename?: 'MilitaryTimeSlot', start_time_military?: string | null, end_time_military?: string | null, time_zone_id?: string | null } | null } | null };

export type GetAllAvailabilitiesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllAvailabilitiesQuery = { __typename?: 'Query', providerAvailabilities?: Array<{ __typename?: 'ProviderAvailability', id: string, location?: { __typename?: 'ProviderLocation', address?: string | null, name?: string | null, radius_meters?: number | null, latitude?: number | null, longitude?: number | null } | null, reservation_window?: { __typename?: 'TimeSlot', start_time?: number | null, end_time?: number | null } | null, time_slots?: Array<{ __typename?: 'BreakTimeSlot', id?: string | null, start_time?: number | null, end_time?: number | null, break?: { __typename?: 'ProviderBreak', id?: string | null, name?: string | null } | null } | { __typename?: 'ReservationTimeSlot', id?: string | null, start_time?: number | null, end_time?: number | null } | { __typename?: 'TimeSlot', id?: string | null, start_time?: number | null, end_time?: number | null }> | null, series?: { __typename?: 'ProviderAvailabilitySeries', id: string, frequency?: Types.AvailabilityFrequency | null, recurring_days?: Array<number> | null, location?: { __typename?: 'ProviderLocation', address?: string | null, name?: string | null, radius_meters?: number | null, latitude?: number | null, longitude?: number | null } | null, reservation_window?: { __typename?: 'MilitaryTimeSlot', start_time_military?: string | null, end_time_military?: string | null, time_zone_id?: string | null } | null } | null }> | null };

export type CreateAvailabilityMutationVariables = Types.Exact<{
  input: Types.ProviderAvailabilityCreateInput;
}>;


export type CreateAvailabilityMutation = { __typename?: 'Mutation', providerAvailabilityCreate?: { __typename?: 'ProviderAvailability', id: string, location?: { __typename?: 'ProviderLocation', address?: string | null, name?: string | null, radius_meters?: number | null, latitude?: number | null, longitude?: number | null } | null, reservation_window?: { __typename?: 'TimeSlot', start_time?: number | null, end_time?: number | null } | null, time_slots?: Array<{ __typename?: 'BreakTimeSlot', id?: string | null, start_time?: number | null, end_time?: number | null, break?: { __typename?: 'ProviderBreak', id?: string | null, name?: string | null } | null } | { __typename?: 'ReservationTimeSlot', id?: string | null, start_time?: number | null, end_time?: number | null } | { __typename?: 'TimeSlot', id?: string | null, start_time?: number | null, end_time?: number | null }> | null, series?: { __typename?: 'ProviderAvailabilitySeries', id: string, frequency?: Types.AvailabilityFrequency | null, recurring_days?: Array<number> | null, location?: { __typename?: 'ProviderLocation', address?: string | null, name?: string | null, radius_meters?: number | null, latitude?: number | null, longitude?: number | null } | null, reservation_window?: { __typename?: 'MilitaryTimeSlot', start_time_military?: string | null, end_time_military?: string | null, time_zone_id?: string | null } | null } | null } | null };

export type UpdateAvailabilityMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  input: Types.ProviderAvailabilityUpdateInput;
}>;


export type UpdateAvailabilityMutation = { __typename?: 'Mutation', providerAvailabilityUpdate?: { __typename?: 'ProviderAvailability', id: string, location?: { __typename?: 'ProviderLocation', address?: string | null, name?: string | null, radius_meters?: number | null, latitude?: number | null, longitude?: number | null } | null, reservation_window?: { __typename?: 'TimeSlot', start_time?: number | null, end_time?: number | null } | null, time_slots?: Array<{ __typename?: 'BreakTimeSlot', id?: string | null, start_time?: number | null, end_time?: number | null, break?: { __typename?: 'ProviderBreak', id?: string | null, name?: string | null } | null } | { __typename?: 'ReservationTimeSlot', id?: string | null, start_time?: number | null, end_time?: number | null } | { __typename?: 'TimeSlot', id?: string | null, start_time?: number | null, end_time?: number | null }> | null, series?: { __typename?: 'ProviderAvailabilitySeries', id: string, frequency?: Types.AvailabilityFrequency | null, recurring_days?: Array<number> | null, location?: { __typename?: 'ProviderLocation', address?: string | null, name?: string | null, radius_meters?: number | null, latitude?: number | null, longitude?: number | null } | null, reservation_window?: { __typename?: 'MilitaryTimeSlot', start_time_military?: string | null, end_time_military?: string | null, time_zone_id?: string | null } | null } | null } | null };

export type DeleteAvailabilityMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteAvailabilityMutation = { __typename?: 'Mutation', providerAvailabilityDelete?: { __typename?: 'ProviderAvailabilityDeletePayload', provider_availability_id?: string | null } | null };

export type CreateAvailabilitySeriesMutationVariables = Types.Exact<{
  input: Types.ProviderAvailabilitySeriesCreateInput;
}>;


export type CreateAvailabilitySeriesMutation = { __typename?: 'Mutation', providerAvailabilitySeriesCreate?: { __typename?: 'ProviderAvailabilitySeries', id: string, frequency?: Types.AvailabilityFrequency | null, recurring_days?: Array<number> | null, location?: { __typename?: 'ProviderLocation', address?: string | null, name?: string | null, radius_meters?: number | null, latitude?: number | null, longitude?: number | null } | null, reservation_window?: { __typename?: 'MilitaryTimeSlot', start_time_military?: string | null, end_time_military?: string | null, time_zone_id?: string | null } | null } | null };

export type UpdateAvailabilitySeriesMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  input: Types.ProviderAvailabilitySeriesUpdateInput;
}>;


export type UpdateAvailabilitySeriesMutation = { __typename?: 'Mutation', providerAvailabilitySeriesUpdate?: { __typename?: 'ProviderAvailabilitySeries', id: string, frequency?: Types.AvailabilityFrequency | null, recurring_days?: Array<number> | null, location?: { __typename?: 'ProviderLocation', address?: string | null, name?: string | null, radius_meters?: number | null, latitude?: number | null, longitude?: number | null } | null, reservation_window?: { __typename?: 'MilitaryTimeSlot', start_time_military?: string | null, end_time_military?: string | null, time_zone_id?: string | null } | null } | null };

export type DeleteAvailabilitySeriesMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteAvailabilitySeriesMutation = { __typename?: 'Mutation', providerAvailabilitySeriesDelete?: { __typename?: 'ProviderAvailabilitySeriesDeletePayload', provider_availability_series_id?: string | null } | null };

export const ProviderAvailabilityLocationFragmentDoc = gql`
    fragment ProviderAvailabilityLocation on ProviderLocation {
  address
  name
  radius_meters
  latitude
  longitude
}
    `;
export const ProviderAvailabilitySeriesDetailsFragmentDoc = gql`
    fragment ProviderAvailabilitySeriesDetails on ProviderAvailabilitySeries {
  id
  frequency
  location {
    ...ProviderAvailabilityLocation
  }
  reservation_window {
    start_time_military
    end_time_military
    time_zone_id
  }
  recurring_days
}
    ${ProviderAvailabilityLocationFragmentDoc}`;
export const ProviderAvailabilityDetailsFragmentDoc = gql`
    fragment ProviderAvailabilityDetails on ProviderAvailability {
  id
  location {
    ...ProviderAvailabilityLocation
  }
  reservation_window {
    start_time
    end_time
  }
  time_slots {
    id
    start_time
    end_time
    ... on BreakTimeSlot {
      break {
        id
        name
      }
    }
  }
  series {
    ...ProviderAvailabilitySeriesDetails
  }
}
    ${ProviderAvailabilityLocationFragmentDoc}
${ProviderAvailabilitySeriesDetailsFragmentDoc}`;
export const GetAllAvailabilitiesDocument = gql`
    query GetAllAvailabilities {
  providerAvailabilities {
    ...ProviderAvailabilityDetails
  }
}
    ${ProviderAvailabilityDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllAvailabilitiesGQL extends Apollo.Query<GetAllAvailabilitiesQuery, GetAllAvailabilitiesQueryVariables> {
    document = GetAllAvailabilitiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAvailabilityDocument = gql`
    mutation CreateAvailability($input: ProviderAvailabilityCreateInput!) {
  providerAvailabilityCreate(input: $input) {
    ...ProviderAvailabilityDetails
  }
}
    ${ProviderAvailabilityDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAvailabilityGQL extends Apollo.Mutation<CreateAvailabilityMutation, CreateAvailabilityMutationVariables> {
    document = CreateAvailabilityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAvailabilityDocument = gql`
    mutation UpdateAvailability($id: String!, $input: ProviderAvailabilityUpdateInput!) {
  providerAvailabilityUpdate(id: $id, input: $input) {
    ...ProviderAvailabilityDetails
  }
}
    ${ProviderAvailabilityDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAvailabilityGQL extends Apollo.Mutation<UpdateAvailabilityMutation, UpdateAvailabilityMutationVariables> {
    document = UpdateAvailabilityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAvailabilityDocument = gql`
    mutation DeleteAvailability($id: String!) {
  providerAvailabilityDelete(id: $id) {
    provider_availability_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAvailabilityGQL extends Apollo.Mutation<DeleteAvailabilityMutation, DeleteAvailabilityMutationVariables> {
    document = DeleteAvailabilityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAvailabilitySeriesDocument = gql`
    mutation CreateAvailabilitySeries($input: ProviderAvailabilitySeriesCreateInput!) {
  providerAvailabilitySeriesCreate(input: $input) {
    ...ProviderAvailabilitySeriesDetails
  }
}
    ${ProviderAvailabilitySeriesDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAvailabilitySeriesGQL extends Apollo.Mutation<CreateAvailabilitySeriesMutation, CreateAvailabilitySeriesMutationVariables> {
    document = CreateAvailabilitySeriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAvailabilitySeriesDocument = gql`
    mutation UpdateAvailabilitySeries($id: String!, $input: ProviderAvailabilitySeriesUpdateInput!) {
  providerAvailabilitySeriesUpdate(id: $id, input: $input) {
    ...ProviderAvailabilitySeriesDetails
  }
}
    ${ProviderAvailabilitySeriesDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAvailabilitySeriesGQL extends Apollo.Mutation<UpdateAvailabilitySeriesMutation, UpdateAvailabilitySeriesMutationVariables> {
    document = UpdateAvailabilitySeriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAvailabilitySeriesDocument = gql`
    mutation DeleteAvailabilitySeries($id: String!) {
  providerAvailabilitySeriesDelete(id: $id) {
    provider_availability_series_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAvailabilitySeriesGQL extends Apollo.Mutation<DeleteAvailabilitySeriesMutation, DeleteAvailabilitySeriesMutationVariables> {
    document = DeleteAvailabilitySeriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }