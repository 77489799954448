import {Pipe, PipeTransform} from '@angular/core';
import {ReservationAction, ReservationActionType, ReservationStatus} from '../gql/generated/graphql-schema';
import {RemoteConfigsService} from '../services/remote-configs.service';
import {toTitleCase} from '../utils/app.utils';


@Pipe({
    name: 'findReservationActionPipe'
})
export class FindReservationActionPipePipe implements PipeTransform {
    transform(actionConfigs: ReservationAction[], type: ReservationActionType): ReservationAction | undefined {
        return actionConfigs.find(r => r.type === type);
    }
}

@Pipe({
    name: 'reservationStatusText'
})
export class ReservationStatusTextPipe implements PipeTransform {
    constructor(
        private remoteConfigService: RemoteConfigsService
    ) {
    }

    transform(status: ReservationStatus) {
        return this.remoteConfigService.reservationStatusText?.[status]
            ?? toTitleCase(status.toString().replace("_", " ").replace("-", ""));
    }
}
