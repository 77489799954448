import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {IonicModule} from '@ionic/angular';
import {TextModule} from '../modules/text.module';
import {TaskStatusComponent} from './task-status.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        TextModule
    ],
    declarations: [
        TaskStatusComponent
    ],
    exports: [
        TaskStatusComponent
    ],
    providers: []
})
export class TaskStatusModule {}
