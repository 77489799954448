import * as fromUser from '../actions/user.actions';
import {PhoneNumberVerificationResult} from '@gobubbleapp/users-ui';
import {EnrichedUser} from '../models/enriched-user';
import {UserLocation} from '../gql/generated/graphql-schema';

export enum EmailLoginState {
    DID_NOT_CHECK,
    UNAUTHENTICATED,
    AUTHENTICATED,
    LOGGING_IN,
    CHECKED_EXPERT
}

export interface UserState {
    verificationResult: PhoneNumberVerificationResult | null;
    user?: EnrichedUser;
    loginRequestEmail: string | null;
    emailLoginState: EmailLoginState;
    phoneNumberPendingVerification: string | null;
    loading: boolean;
    staticMapImageUrls: {[key: string]: string};
    userLocation?: Pick<UserLocation, 'latitude' | 'longitude'>;
}

export const initialState: UserState = {
    verificationResult: null,
    loginRequestEmail: null,
    emailLoginState: EmailLoginState.DID_NOT_CHECK,
    phoneNumberPendingVerification: null,
    loading: false,
    staticMapImageUrls: {}
};

export function reducer(
    state = initialState,
    action: fromUser.ActionsUnion
): UserState {
    switch (action.type) {
        case fromUser.ActionTypes.LINK_PHONE_NUMBER: {
            return {
                ...state,
                phoneNumberPendingVerification: action.phoneNumber
            };
        }

        case fromUser.ActionTypes.CONFIRM_PHONE_NUMBER_SUCCESS: {
            return {
                ...state,
                phoneNumberPendingVerification: null
            };
        }

        case fromUser.ActionTypes.USER_AUTH_STATE_CHANGED: {
            return {
                ...state,
                user: action.user ? {
                    ...action.user,
                    is_expert: false
                } : null,
                emailLoginState: action.user ? EmailLoginState.AUTHENTICATED : EmailLoginState.UNAUTHENTICATED
            };
        }

        case fromUser.ActionTypes.IS_EXPERT_RESULT: {
            return {
                ...state,
                user: state.user ? {
                    ...state.user,
                    is_expert: action.isExpert
                } : null,
                emailLoginState: EmailLoginState.CHECKED_EXPERT
            };
        }

        case fromUser.ActionTypes.SIGN_IN_EMAIL_PASSWORD:
        case fromUser.SignUpWithEmailPassword.type:
        case fromUser.ActionTypes.USER_LOGOUT:
            return {
                ...state,
                loading: true
            };

        case fromUser.ActionTypes.SIGN_IN_EMAIL_PASSWORD_FAILURE:
        case fromUser.ActionTypes.SIGN_IN_EMAIL_PASSWORD_SUCCESS:
        case fromUser.ActionTypes.USER_LOG_OUT_SUCCESS:
        case fromUser.ActionTypes.USER_LOG_OUT_FAILURE:
            return {
                ...state,
                loading: false
            };

        case fromUser.ActionTypes.GET_STATIC_MAP_URL_SUCCESS:
            return {
                ...state,
                staticMapImageUrls: {
                    ...state.staticMapImageUrls,
                    [action.locationId]: action.url
                }
            };

        case fromUser.UpdateUserLocation.type:
            return {
                ...state,
                userLocation: {
                    latitude: action.latitude,
                    longitude: action.longitude
                }
            }

        default: {
            return state;
        }
    }
}

export const userLoadingState = (state: UserState) => state.loading;
export const selectUserLocation = (state: UserState) => state.userLocation;
