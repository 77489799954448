// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollable-containers {
  padding: var(--ion-padding, 16px) 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImltYWdlLXNsaWRlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLG1DQUFBO0FBQ0YiLCJmaWxlIjoiaW1hZ2Utc2xpZGVyLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnNjcm9sbGFibGUtY29udGFpbmVycyAge1xuICBwYWRkaW5nOiB2YXIoLS1pb24tcGFkZGluZywgMTZweCkgMDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/image-slider/image-slider.component.scss"],"names":[],"mappings":"AAAA;EACE,mCAAA;AACF;AACA,wWAAwW","sourcesContent":[".scrollable-containers  {\n  padding: var(--ion-padding, 16px) 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
