// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-input-item {
  margin-left: var(--ion-margin);
  --padding-start: 0;
}
.icon-input-item .service-icon {
  font-size: 50px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByb3ZpZGVyLXNlcnZpY2UtZm9ybS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNFLDhCQUFBO0VBQ0Esa0JBQUE7QUFBRjtBQUVFO0VBQ0UsZUFBQTtBQUFKIiwiZmlsZSI6InByb3ZpZGVyLXNlcnZpY2UtZm9ybS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIlxuLmljb24taW5wdXQtaXRlbSB7XG4gIG1hcmdpbi1sZWZ0OiB2YXIoLS1pb24tbWFyZ2luKTtcbiAgLS1wYWRkaW5nLXN0YXJ0OiAwO1xuXG4gIC5zZXJ2aWNlLWljb24ge1xuICAgIGZvbnQtc2l6ZTogNTBweDtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/provider-services/provider-service-form/provider-service-form.component.scss"],"names":[],"mappings":"AACA;EACE,8BAAA;EACA,kBAAA;AAAF;AAEE;EACE,eAAA;AAAJ;AACA,wfAAwf","sourcesContent":["\n.icon-input-item {\n  margin-left: var(--ion-margin);\n  --padding-start: 0;\n\n  .service-icon {\n    font-size: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
