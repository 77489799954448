import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GoogleMapsService {
    private loader: Loader;

    constructor() {
        this.loader = new Loader({
            apiKey: environment.expertsFirebase.apiKey,
            version: "weekly",
            libraries: ["places", ]
        });
    }

    getInstance() {
        return this.loader.load();
    }
}
