import * as moment from 'moment-timezone';
import {MilitaryTimeSlot, TimeSlot} from '../gql/generated/graphql-schema';

export const DATE_FORMAT = "YYYY-MM-DD";
export const MILITARY_TIME_FORMAT = "HH:mm";

export const weekday = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
];

export const WEEKDAYS_MAPPING = moment.weekdays().map(day => ({name: day, iso: moment(day, "ddd").isoWeekday()}));

export function addZero(v) {
    return Number(v) < 10 ? '0' + v : v;
}

export function getWeekday(date: Date): string {
    const dateMoment = moment(new Date(date));
    return weekday[dateMoment.weekday()];
}

export function getTimeString(epochSec: number): string {
    if (!epochSec) {
        return '';
    }
    return moment(epochSec * 1000).format('h:mm A');
}

export function getDateString(date: Date | number | undefined, format = DATE_FORMAT): string {
    if (!date) {
        return undefined;
    }
    let dateMoment;
    if (typeof date === 'number') {
        dateMoment = moment(date * 1000);
    } else {
        dateMoment = moment(new Date(date));
    }
    return dateMoment.format(format);
}

export function updateMomentCalendar() {
    moment.updateLocale('en', {
        calendar : {
            lastDay : '[Yesterday]',
            sameDay : '[Today]',
            nextDay : '[Tomorrow]',
            lastWeek : 'dddd',
            nextWeek : 'dddd',
            sameElse : '[Next] dddd'
        }
    });
}

export function getEpochSec(date: Date) {
    return Math.floor(date.getTime() / 1000);
}

export function getDateFromEpochSec(epochSec: number): Date {
    if (!epochSec) {
        return undefined;
    }
    return new Date(epochSec * 1000);
}

export function getMomentsFromTimeSlot(timeSlot: TimeSlot | MilitaryTimeSlot, date?: string): {startTime: moment.Moment, endTime: moment.Moment} {
    if (!timeSlot) {
        return {startTime: undefined, endTime: undefined};
    }

    const timezone = timeSlot?.time_zone_id ?? moment.tz.guess();

    if ('start_time_military' in timeSlot) {
        const dateMoment = date ? moment.tz(date, timezone) : moment.tz(timezone);

        const startTime = moment.tz(dateMoment, timezone);
        startTime.set({
            hour: parseInt(timeSlot.start_time_military.substr(0, 2)),
            minute: parseInt(timeSlot.start_time_military.substr(3, 2)),
            seconds: 0,
            millisecond: 0
        });

        const endTime = moment.tz(dateMoment, timezone);
        endTime.set({
            hour: parseInt(timeSlot.end_time_military.substr(0, 2)),
            minute: parseInt(timeSlot.end_time_military.substr(3, 2)),
            seconds: 0,
            millisecond: 0
        });

        return {startTime, endTime}
    }

    if ('start_time' in timeSlot) {
        const startTime = moment.tz(timeSlot.start_time * 1000, timezone);
        const endTime = moment.tz(timeSlot.end_time * 1000, timezone);
        return {startTime, endTime}
    }

    return {startTime: undefined, endTime: undefined};
}

export function militaryToStandardTime(time: string) {
    if (!time) {
        return undefined;
    }
    let hour: number;
    let minute: string;
    if (time.length === 4) {
        hour = parseInt(time.substring(0, 1));
        minute = time.substring(2);     // we skip the : character
    } else {
        hour = parseInt(time.substring(0, 2));
        minute = time.substring(3);     // we skip the : character
    }

    let period = hour >= 12 ? "PM" : "AM";
    hour = hour % 12;
    hour = hour ? hour : 12; // convert 0 to 12
    return `${hour}:${minute} ${period}`;
}

export function momentTimezoneFromTimeSlot(timeSlot: TimeSlot) {
    const timezone = timeSlot.time_zone_id ?? moment.tz.guess();
    const startTime = moment.tz(timeSlot.start_time * 1000, timezone);
    const endTime = moment.tz(timeSlot.end_time * 1000, timezone);
    return {startTime, endTime}
}

export function momentTimezoneFromDate(date: Date, timeZone?: string) {
    return moment.tz(date, timeZone ?? moment.tz.guess());
}

export function timeSlotsOverlap(timeSlot1: TimeSlot, timeSlot2: TimeSlot): boolean {

    if (timeSlot1.start_time === timeSlot2.start_time && timeSlot1.end_time === timeSlot2.end_time) {
        return true;
    }

    const timeSlot1StartTime = moment(timeSlot1.start_time * 1000);
    const timeSlot1EndTime = moment(timeSlot1.end_time * 1000);
    const timeSlot2StartTime = moment(timeSlot2.start_time * 1000);
    const timeSlot2EndTime = moment(timeSlot2.end_time * 1000);

    return timeSlot1StartTime.isBetween(timeSlot2StartTime, timeSlot2EndTime, 'seconds', '()')
        || timeSlot1EndTime.isBetween(timeSlot2StartTime, timeSlot2EndTime, 'seconds', '()')
        || timeSlot2StartTime.isBetween(timeSlot1StartTime, timeSlot1EndTime, 'seconds', '()')
        || timeSlot2EndTime.isBetween(timeSlot1StartTime, timeSlot1EndTime, 'seconds', '()')
}

/**
 * Checks if the passed time slot doesn't overlap with any of the other time slots in the array.
 */
export function timeSlotOverlapsWithList(timeSlot: TimeSlot, timeSlots: TimeSlot[]): boolean {
    for (const t of timeSlots) {
        if (t.start_time && t.end_time && timeSlotsOverlap(timeSlot, t)) {
            return true;
        }
    }
    return false;
}
